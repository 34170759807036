import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import img1 from '../../images/kaas_home.jpg';
import card_img1 from '../../images/card1.jpg';
import card_img2 from '../../images/card2.jpg';
import card_img3 from '../../images/card3.jpg';
import card_img4 from '../../images/card4.jpg';
import hover1 from '../../images/hover1.jpg';
import hover2 from '../../images/hover2.jpg';
import hover3 from '../../images/hover3.jpg';
import cimg1 from '../../images/img1.jpg';
import cimg2 from '../../images/img2.jpg';
import cimg3 from '../../images/img3.jpg';
import Footer from '../../components/footer/footer.js';
import Aiconics from '../../images/aiconics.jpg';
import OCR from '../../images/ocr.jpg';
import Amigo from '../../images/amigo.jpg';
import BotMetrics from '../../images/botmetrics.JPG';
import '../../App.css';


class HomePage extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          hoverimg:hover1
        }
      }
    
      mouseOver1 = () => {this.setState({hoverimg: hover1})}
      mouseOver2 = () => {this.setState({hoverimg: hover2})}
      mouseOver3 = () => {this.setState({hoverimg: hover3})}
    
    render() {
    
    return (
<div className="container-fluid pl-0 pr-0">
  <div id="demo" className="carousel slide ml-0" data-ride="carousel"  style={{"position":"relative"}}>
  <div className="text-left d-block mx-auto pl-4" style={{"position":"absolute",opacity: 0.7,color:"black","zIndex":"111","marginTop":"40px","display": "inline-block","fontSize": "6vw","wordWrap":"break-word","lineHeight":"25vh"}}>
 
    <span style={{"opacity": 0.9,backgroundColor: "lightgray"}}>Knowledge isn't Power</span> <br/> <span style={{"opacity": 0.9,backgroundColor: "lightgray"}}>Until it is Applied</span></div>
  
 
  <div class="carousel-inner">
    <div className="carousel-item active w-100" style={{"overflow":"hidden","boxSizing":"content-box"}}>
      <img src={img1} alt="Los Angeles"   className="w-100 img-fluid h-50"/>
      
      
      <div class="carousel-caption text-left " style={{"left":"1%"}}>
        <h1 style={{"color":"ghostwhite"}}>KaaS is AI for your Business</h1>
        <Link to="/offerings"><span className="btn btn-primary">Start your AI journey</span></Link>
      </div>   
    </div>
   
  </div>

</div>
<section className="p-5 bg-light d-flex flex-row">
  <div className="w-25">
  <h5 className="font-weight-bold">News &amp; Resources</h5>
  <p>The latest on KaaS - Knowledge as a Service<br/> from events to solution releases.</p>
  </div>
  <div className="w-75">

  <div id="myCarousel" class="carousel slide" data-ride="carousel" data-interval="false">

  <ul class="carousel-indicators text-left text-dark" >
    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
    <li data-target="#myCarousel" data-slide-to="1"></li>
    
  </ul>
  
  <div class="carousel-inner">
  <div class="carousel-item active">
  <div className="card-deck">
  <div class="card">
  <a href="https://aibusiness.com/aiconics-awards-san-francisco-2019-winners-announced/#ai7" target="blank"><img class="card-img-top" src={Aiconics} alt="Card image"/></a>  <div class="card-body">
    <h4 class="card-title font-weight-bold">AIconics San Francisco 2019</h4>
    <p class="card-text"><span className="font-weight-bold">KaaS Cognitive Search</span> wins the runner up award at <span className="font-weight-bold">AIconics San Francisco 2019</span> in the <span className="font-weight-bold">Best Innovation in NLP</span> category</p>
   {/* <a href="https://aibusiness.com/aiconics-awards-san-francisco-2019-winners-announced/#ai7" target="blank" class="fa fa-arrow-right float-right"></a>*/}
  </div>
  </div>
  <div class="card">
  <img class="card-img-top" src={OCR} alt="Card image"/>
  <div class="card-body">
    <h4 class="card-title font-weight-bold">OCR Documents &amp; Images Support</h4>
    <p class="card-text">Happy to announce that KaaS Cognitive Search now supports extracting insights from OCR documents and images</p>
    {/*<a href="#" class="fa fa-arrow-right float-right"></a>*/}
  </div>
  </div>
  <div class="card">
  <img class="card-img-top" src={Amigo} alt="Card image"/>
  <div class="card-body">
    <h4 class="card-title font-weight-bold">AMIGO goes live!</h4>
    <p class="card-text">Our very own Chatbot for New Hires at Publicis Sapient has gone live. It is built using KaaS Cognitive Chatbot framework</p>
   {/*<a href="#" class="fa fa-arrow-right float-right"></a>*/}
  </div>
  </div>
  <div class="card">
  <img class="card-img-top border-bottom pb-1 pt-1" src={BotMetrics} alt="Card image"/>
  <div class="card-body">
    <h4 class="card-title font-weight-bold">KaaS releases Bot Metrics v1.0</h4>
    <p class="card-text">Use KaaS Bot Metrics to unlock insights from chatbot conversations to create the right AI-powered conversational experience.</p>
    {/*<a href="#" class="fa fa-arrow-right float-right"></a>*/}
  </div>
  </div>  </div>
</div>


{/*<div class="carousel-item">
<div className="card-deck">
  <div class="card">
  <img class="card-img-top" src={card_img1} alt="Card image"/>
  <div class="card-body">
    <h4 class="card-title">John Doe</h4>
    <p class="card-text">Some example text.</p>
    <a href="#" class="fa fa-arrow-right float-right"></a>
  </div>
  </div>
  <div class="card">
  <img class="card-img-top" src={card_img2} alt="Card image"/>
  <div class="card-body">
    <h4 class="card-title">John Doe</h4>
    <p class="card-text">Some example text.</p>
    <a href="#" class="fa fa-arrow-right float-right"></a>
  </div>
  </div>
  <div class="card">
  <img class="card-img-top" src={card_img3} alt="Card image"/>
  <div class="card-body">
    <h4 class="card-title">John Doe</h4>
    <p class="card-text">Some example text.</p>
    <a href="#" class="fa fa-arrow-right float-right"></a>
  </div>
  </div>
  <div class="card">
  <img class="card-img-top" src={card_img4} alt="Card image"/>
  <div class="card-body">
    <h4 class="card-title">John Doe</h4>
    <p class="card-text">Some example text.</p>
    <a href="#" class="fa fa-arrow-right float-right"></a>
  </div>
  </div>
  </div>
</div>*/}


</div></div>
  
  
  </div>
</section>
<section className="p-5 d-flex flex-row" style={{backgroundColor:"#010d14"}}>  
  <div className="w-25 text-white d-flex flex-column" >
 <h1 className="text-white w-100 pr-2 float-left">A modern HOW for <br/>developing AI-enabled <br/>Knowledge Management <br/>solutions.</h1>
<p className="mt-5 pt-4 text-white w-100 how ml-0">KaaS services that uses tools, like text analytics and machine learning, to extract useful knowledge from unstructured data sources. Our services act as building blocks, allowing you to create AI-powered solutions geared toward transforming fragmented pockets of unstructured data into integrated, smart and efficient knowledge discovery.</p>
    </div>
    <div className="w-75 text-center">
    <iframe width="1015" height="571" src="https://www.youtube.com/embed/b3lHMALNtiI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</section>
{/*<section className="bg-light p-4 clearfix d-flex flex-row">
<div className="float-left" >
   <img src={this.state.hoverimg} alt="AI" style={{"objectFit":"cover","width":"714px"}}/>
</div>
<div className="w-50 pl-0 float-right">

<div class="card-rows d-flex flex-column" id="hoverimg">
    <div class="card bg-white p-3 w-50" onMouseOver={this.mouseOver1}>
    <h6 class="card-text">Increase your productivity with AI</h6>
      <div class="card-body pt-5 pb-0">
       
      <a href="#" class="fa fa-arrow-right float-right"></a>
      </div>
    </div>
    <div class="card bg-white p-3 w-50" onMouseOver={this.mouseOver2}>
    <h6 class="card-text">AI for customer service</h6>
      <div class="card-body pt-5 pb-0">
      <a href="#" class="fa fa-arrow-right float-right"></a>
      </div>
    </div>
    
    <div class="card bg-white p-3 w-50" onMouseOver={this.mouseOver3}>
    <h6 class="card-text">AI for contract governance</h6>
      <div class="card-body pt-5 pb-0">
      <a href="#" class="fa fa-arrow-right float-right"></a>
      </div>
    </div>

    </div>



</div>
  </section>*/}

{/*<section>

      <div id="clientstories" class="carousel carousel-fade" data-ride="carousel">

<ul class="carousel-indicators text-left text-dark" >
  <li data-target="#clientstories" data-slide-to="0" class="active"></li>
  <li data-target="#clientstories" data-slide-to="1"></li>
  <li data-target="#clientstories" data-slide-to="2"></li>
  
</ul>

<div class="carousel-inner">
<div class="carousel-item active">
<img class="cs_img" src={cimg1} alt="image1"/>
</div>
<div class="carousel-item">
<img class="cs_img" src={cimg2} alt="image2"/>
</div>
<div class="carousel-item">
<img class="cs_img" src={cimg3} alt="image3"/>
</div>

</div>


</div>
</section>*/}

  <Footer/>

</div>
);
}
}
export default HomePage;