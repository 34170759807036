
import React, { Component } from 'react';

import logo from '../../images/logo.JPG';
import kaasLogo from '../../images/kaas_logo.JPG';
import '../../App.css';
import Footer from '../footer/footer.js';
import Header from '../header/header.js';
import card_img1 from '../../images/card1.jpg';
import card_img2 from '../../images/card2.jpg';
import card_img3 from '../../images/card3.jpg';
import card_img4 from '../../images/card4.jpg';
import NER from '../../images/services/ner.jpg';
import MC from '../../images/services/mc.jpg';

import axios from 'axios';
import MDSpinner from "react-md-spinner";
import {
  Button,
  CardActions,
  FileUpload,
  LinearProgress,
  Snackbar,
  TextField,
  FontIcon,
  Checkbox,
  SelectField,
  SelectionControlGroup,
  FileInput,
  TabsContainer, Tabs, Tab
} from 'react-md';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as apiConstants from '../../service-api-constants.js';
import ReactTable from 'react-table';
import 'react-table/react-table.css';


class RelationExtraction extends Component {
  constructor(props) {
    super(props);

    this.state = {
        sentence: "",
        result: null,
        modelNameControls: [],
        selectedModel: [],
        error:'',
        loaded:true,
        offerings:["Stanford","Reverb","Olllie"],
        Stanford: true,
        Reverb:true,
        Olllie:true,
        selectedOfferings:["Stanford","Reverb","Olllie"]
    }
}

analyze = () =>{
  
 var reqData = {"sentence" : this.state.sentence};
 this.setState({loaded:false,error:'',result: null})
 /* axios({
    method: 'post',
    url: 'http://13.58.187.191:8080/ner',
    config: { headers: {'Content-Type': 'application/json' },
    body: reqData
}
    })
    fetch('http://13.58.187.191:8080/ner', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(reqData)
  })*/
  const offerings = this.state.selectedOfferings;
 // console.log("offerings",offerings.join(","));
  axios.post(apiConstants.RELATION_EXTRACTION, {"content": this.state.sentence, "offerings": offerings.join(",")},
    {headers: {'Content-Type': 'application/json' }}
      )  
  .then((res) => {
    console.log(res);      
    this.setState({result:res.data,loaded:true});
    
  }).catch((error) => {
        console.log("error",error);
        this.setState({error: error.toString(),loaded:true,result:
          {
            "relations":[
            {"triples":[{"subject":"RBI","predicate":"have publish","object":"number","confidence":1},{"subject":"RBI","predicate":"have publish number in","object":"its report","confidence":1},{"subject":"they","predicate":"have receive","object":"post demonetization of high value note","confidence":1},{"subject":"they","predicate":"have receive post demonetization at_time","object":"last year","confidence":1},{"subject":"RBI","predicate":"have publish number in","object":"its annual report","confidence":1},{"subject":"they","predicate":"have receive post demonetization on","object":"November 8","confidence":1},{"subject":"they","predicate":"have receive","object":"post demonetization of value note","confidence":1},{"subject":"they","predicate":"have receive post demonetization at_time","object":"year","confidence":1},{"subject":"they","predicate":"have receive","object":"post demonetization","confidence":1},{"subject":"it","predicate":"have get","object":"98.6 % of old 1000 rupee note","confidence":1},{"subject":"it","predicate":"have get back","object":"98.6 % of old 1000 rupee note","confidence":1},{"subject":"crore","predicate":"be","object":"yet return","confidence":0.24511853840911302},{"subject":"crore","predicate":"be","object":"return","confidence":0.24511853840911302},{"subject":"value","predicate":"be","object":"rs 15.2 trillion","confidence":1},{"subject":"note","predicate":"value of be","object":"rs 15.2 trillion","confidence":1},{"subject":"return note","predicate":"value of be","object":"rs 15.2 trillion","confidence":1}],"source":"stanford"},
            {"triples":[{"subject":"its annual report","predicate":"has published the number of","object":"old notes","confidence":0.6926815004590868},{"subject":"they","predicate":"have received","object":"post demonetization of high value notes","confidence":0.11633873283870325},{"subject":"it","predicate":"has got back","object":"98.6 % of all the old 1000 rupees","confidence":0.09930086418591577},{"subject":"back 98.6 % of all the old 1000 rupees","predicate":"note","object":". 8.9 crore old Rs","confidence":0.17112454635619012},{"subject":"Value of returned notes","predicate":"is","object":"Rs 15.2 trillion","confidence":0.768328543323737}],"source":"reverb"},
            {"triples":[{"subject":"The RBI","predicate":"has published","object":"the number of old notes","confidence":0.0577},{"subject":"it","predicate":"has got","object":"back 98.6 % of all the old 1000 rupees","confidence":0.0577},{"subject":"they","predicate":"which have received","object":"post demonetization of high value notes","confidence":0.0577},{"subject":"Rs 15.2 trillion","predicate":"be Value of","object":"returned notes","confidence":0.0477},{"subject":"they","predicate":"have received post demonetization of high value notes on","object":"November 8","confidence":0.0444},{"subject":"they","predicate":"which have received on","object":"November 8","confidence":0.0444},{"subject":"Value of returned notes","predicate":"is","object":"Rs 15.2 trillion","confidence":0.0084}],"source":"ollie"}
            ]
          }
        });
    });
};

modelChange = (value,e) => {
  console.log("modelChangevalue",value);
  const items = this.state.modelNameControls;
    const selModel = this.state.selectedModel;
  if(value){
    
    items.map((itm)=>{
      if(itm.value == e.target.value){
        itm.checked = value;
      }
    });

   

if (selModel.indexOf(e.target.value) === -1) selModel.push(e.target.value);

    this.setState({selectedModel : selModel,modelNameControls:items});
  }else{   
    items.map((itm)=>{
      if(itm.value == e.target.value){
        itm.checked = value;
      }
    });

    var index = selModel.indexOf(e.target.value);
    if (index > -1) {
      selModel.splice(index, 1);
    }
    this.setState({selectedModel : selModel,modelNameControls:items});
  }
  
};  

sentenceChange = (value) => {
  this.setState({sentence: value});
};

exampleSelect = (value) => {
  this.setState({result: null,sentence: value,error:''});
};

handleOfferingsChange = (value,event) => {
  const offerings = this.state.selectedOfferings;
 
  if(event.target.checked){
    const offeringsSelected = offerings.filter((offering)=>{
      return offering == event.target.value
    });
    if(offeringsSelected.length == 0){
       //const setOfferings = this.state.selectedOfferings;
       offerings.push(event.target.value);
       console.log("offerings",offerings);
       this.setState({ [event.target.value]: event.target.checked,selectedOfferings: offerings });
    }
  }else{
    const offeringsSelected = offerings.filter((offering)=>{
      return offering == event.target.value
    });
    if(offeringsSelected.length > 0){
         const selectedOfferings = this.state.selectedOfferings;
         const offeringsUpdate = selectedOfferings.filter((offering)=>{
          return offering != event.target.value
        });
        console.log("offeringsUpdate",offeringsUpdate);
        this.setState({ [event.target.value]: event.target.checked,selectedOfferings: offeringsUpdate });
   }
  }  
};
    
    render() {
      
    return (
    <div className="container-fluid pl-0 pr-0 ">
    <div className="d-flex flex-row w-100">
    <div className="w-10 p-5">
    <Link to="/offerings/named-entity-recognition"  className="fa fa-arrow-left float-left pl-2 mx-auto"> Back</Link>
      </div>
      <div className="pl-3 pt-4 pb-5 pr-3  w-90">  
        <h2 className="pt-3 pl-3">Relation Extraction</h2>
       <div class="card w-100 border-0">
       <div class="card-body pt-2">
       <div className="d-flex flex-column">
       <div className="d-flex flex-row">
       

    <div className="d-flex flex-column w-75">
    
    <div className="d-flex flex-row"><span className="d-inline-block align-self-start" style={{"whiteSpace":"nowrap","lineHeight":"55px","fontSize":"14px"}}>Enter text or </span>
       <SelectField
      id="mc_example"
      placeholder="Choose an example..."
      className="md-cell d-block w-100"
      
      menuItems={["The RBI in its annual report has published the number of old notes which they have received post demonetization of high value notes on November 8 last year. According to RBI, it has got back 98.6% of all the old 1000 rupees note. 8.9 crore old Rs 1,000 notes out of 632.6 crore are yet to be returned post demonetization. Value of returned notes is Rs 15.2 trillion.","she worked at stephen & co when everything crashed.","his father worked for state bank of india since 1985."]}    
      
      onChange={this.exampleSelect} 
      value={this.state.sentence}
    /></div>
    
    <TextField
      id="floating-center-title"
     
      lineDirection="center"
      placeholder="Type a sentence"
      className="text-field mt-3 w-100"
      value={this.state.sentence}
      rows={1}
      onChange={this.sentenceChange}
    />
    
     </div>




     <div className="pt-3">
<h5 className="pl-5">Offerings</h5>
{this.state.offerings.map((item,index)=>(
  <Checkbox
  id={`checkbox${index}`}     
  name={item}    
  label={item}
  value={item}
  checked={this.state[item]}
  inline={false}
  onChange={this.handleOfferingsChange}
  className="ml-5"
/>
)) }
<Button className="mt-4 ml-5 align-self-end mb-1 rounded-pill white-space" onClick={this.analyze} raised primary>Analyze</Button>
       </div>
      </div>
      
        
    
      </div> 

       </div>
       </div>  
       {!this.state.loaded ? <MDSpinner singleColor="#03a9f4" className="m-5" size={70} borderSize={5}/> : ""}
      {this.state.result != null ? <div class="card w-100 border-0">
       <div class="card-body pt-0">
       <h3>Results</h3>
       <TabsContainer>
    <Tabs tabId="simple-tab" colored={false}>
      
      {this.state.result.relations.map((item,index)=>(
        <Tab label={item.source} className="">
      <div className="w-100 mt-2">       
        <ReactTable
        className="-striped -highlight mb-3 table-scroll"
        data={item.triples}
        showPagination={false}
        minRows = {item.triples.length}
        
        columns={[{
          Header: 'Subject',
          accessor: 'subject' // String-based value accessors!,
          
        }, {
          Header: 'Predicate',
          accessor: 'predicate',
        }, {
          Header: 'Object',
          accessor: 'object' // Custom value accessors!
        }, {
          Header: 'Confidence', // Custom header components!
          accessor: 'confidence'
        }]}
      /></div> </Tab>
       ))    }
    </Tabs>
  </TabsContainer>          
         
       </div>
       </div>    : ""} 

       {
         this.state.error.length > 0 ? <span className="text-danger pl-4">{this.state.error}</span> : ""
       }

       </div>  
       </div>
       <Footer/>
 </div>   
);
}
}
export default RelationExtraction;