import React, { Component } from 'react';

//import pslogo from '../../images/logo1.JPG';
import pslogo from '../../images/PS_Logo_RGB.svg';
import kaasLogo from '../../images/kaas_logo.JPG';
import '../../App.css';


class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userName : "",
      Password : ""
    }
  }

  usernameChange = (e) => this.setState({userName:e.target.value});
  passwordChange = (e) => this.setState({Password:e.target.value});
    
    render() {
    
    return (
  
    <div className="container-fluid pl-0 pr-0">
      
      <div className="col-sm-12  pl-0 ">

            <a className="w-100 d-block float-left pb-2 pl-3 border-bottom"><img src={pslogo} className="App-logo float-left mt-3 pr-2 border-right" alt="Curiosum" /> 
            <span className="font-weight-bold pt-3 d-block ">
                <img src={kaasLogo} className="kaas-logo float-left pr-1 pl-2" alt="Curiosum" /> </span>
            {/*<p className="font-weight-bold pt-3 mb-0">Curiosum</p><i className="small">Cognitive Intelligence</i></a>*/}
          
                
               </a>

           
          </div>
          
 
           




<div className="container p-5 d-flex flex-row">
<div className="w-50 p-5 m-0">
<h1 className="text-dark mb-3">KaaS
</h1>
<p className="lead-mktg">
Work smarter, make better strategic decisions, and gain a competitive edge with groundbreaking AI search technology.</p>
<p>Gain dramatic time savings with one search across all content sets and review results on one screen. Stay alerted to new disclosures. Find what others miss.</p>
<p>Advanced Deep Learning algorithms allow you to search across millions of documents with a few clicks, dramatically reducing your time to insight. </p>
        
</div>
<div className="border rounded justify-content-center p-5 w-50 m-5 align-center">
<h3>Log in to KaaS </h3>
          <form>
  <div className="form-group pt-3">
    <label htmlFor="email">Username:</label>
    <input type="email" className="form-control" id="email" autoComplete="off" placeholder="Enter username" defaultValue={this.state.userName} onChange={this.usernameChange} />
  </div>
  <div className="form-group pt-2">
    <label htmlFor="pwd">Password:</label>
    <input type="password" className="form-control" id="pwd" placeholder="Enter Password" defaultValue={this.state.Password} onChange={this.passwordChange} />
  </div>
  { this.props.showError ? <div className="text text-danger pb-2">
      UserName or Password is incorrect.
  </div>: <div></div>
}
  
  <button type="button" onClick={()=>this.props.logIn(this.state.userName,this.state.Password)} className="btn btn-light border rounded-pill font-weight-bold pl-3 pr-3">Submit</button>
</form>
</div>

</div>


    </div>
        
       
        );
      }
    }
    export default Login;
