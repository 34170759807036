

import React, { Component } from 'react';
import '../../App.css';
import Footer from '../footer/footer.js';
import MC from '../../images/services/mc.jpg';
import { TabsContainer, Tabs, Tab } from 'react-md';
import axios from 'axios';
import MDSpinner from "react-md-spinner";
import {
  Button,
  CardActions,
  FileUpload,
  LinearProgress,
  Snackbar,
  TextField,
  FontIcon,
  Checkbox,
  SelectField,
  SelectionControlGroup,
  FileInput
} from 'react-md';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as apiConstants from '../../service-api-constants.js';


class QuestionUnderstanding extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            question: '',
            result: null,
            error:'',
            loaded:true,
            menuItems: ["What do robots that resemble humans attempt to do?",
            "How many passing touchdowns were there in the game?",
            "Who threw the longest touchdown pass of the game?"
          ]
        }
    }

    questionChange = (value) => {
      this.setState({question: value});
    };
    
    exampleSelect = (value) => {
      this.setState({result: null,question: value,error:''});
    };     
    
    analyze = () => {
      if(this.state.question.length > 0){
        this.setState({loaded:false,result:null});
       /* var reqFormData = new FormData();        
        reqFormData.set('question', this.state.question);
        axios({
            method: 'post',
            url: 'http://10.207.11.105:5001/infogen',
            data: reqFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            })*/
            axios.post(apiConstants.QUESTIONUNDERSTANDING, [this.state.question],
    {headers: {'Content-Type': 'application/json' }}
      ) 
            .then((response) => {
                console.log("response", response);
                this.setState({loaded:true,result:response.data["0"]});
            })
            .catch((error) => {
              let res = {
                "0": {
                    "acceptableNERTags": [
                        "LOC",
                        "GPE"
                    ],
                    "answer_type": "location",
                    "context": [
                        "diamond",
                        "diamonds",
                        "sapphire",
                        "gemstone",
                        "jewel"
                    ],
                    "text": "Where can i find diamond ?"
                }
            };
                console.log("error", error);
                this.setState({loaded:true,result:res["0"]});

            });
          }
    }
    
    render() {
    const {result,question} = this.state;    

    const entities = result != null && result.acceptableNERTags.map(function(item,index) {
        return (           
            <span key={index} className={`text-dark d-inline mr-3 mt-2 ${index != 0 ? `border-left pl-3` : ``}`} >{item}</span>
        );
    });

    const context = result != null && result.context.map(function(item,index) {
      return (           
          <button key={index}  disabled={true} className="btn btn-outline-secondary rounded text-dark d-inline mr-3 mt-2" >{item}</button>
      );
  });


    return (

        <div className="container-fluid pl-0 pr-0 ">
        <div className="d-flex flex-row w-100">
        <div className="w-10 p-5">
        <Link to="/offerings/question-understanding"  className="fa fa-arrow-left float-left pl-2 mx-auto"> Back</Link>
          </div>
          <div className="pl-3 pt-4 pb-5 pr-3  w-90">  
            <h2 className="pt-3 pl-3">Answer Type</h2>
            <div class="card w-100 border-0">
       <div class="card-body pt-2">
       <div className="d-flex flex-row">
       <span className="d-inline-block align-self-end" style={{"whiteSpace":"nowrap","lineHeight":"55px","fontSize":"14px"}}>Enter question or </span>
       <SelectField
      id="example"
      placeholder="Choose an example..."
      className="md-cell d-block w-100"
      
      menuItems={this.state.menuItems}    
      
      onChange={this.exampleSelect} 
      value={this.state.question}
    />
      </div>
        <div className="d-flex flex-row"><TextField
      id="floating-center-title"
     label="Question"
      lineDirection="center"
      placeholder="Type a question"
      className="text-field mt-3"
      value={this.state.question}
      rows={1}
      onChange={this.questionChange}
    />
    <Button className="mt-4 ml-3 align-self-end mb-1 rounded-pill" onClick={this.analyze} raised primary>Analyze</Button>
     </div>
       

       </div>
       </div>   
           {!this.state.loaded ? <MDSpinner singleColor="#03a9f4" className="m-5" size={70} borderSize={5}/> : ""}
           {result && <div class="card w-100 border-0 w-50 d-inline-block">
       <div class="card-body">
       <div class="card-title "><h4 className="font-weight-bold">Question</h4></div>

       <div className="p-3 mb-3">
         {result.text}
       </div>

       <h4 className="font-weight-bold">Answer Type</h4>
       <div className="p-3 mb-3"> {result.answer_type}</div>

        <h4 className="font-weight-bold">Expected Tags</h4>
        <div className="p-3 mb-3"> {entities}
        </div>

      
         <h4 className="font-weight-bold">Context</h4>
         <div className="p-3 mb-3"> {context}
        </div>
        
       

        
       </div>
       </div> } 
    
           {
             this.state.error.length > 0 ? <span className="text-danger pl-4">{this.state.error}</span> : ""
           }
    
           </div>  
           </div>
           <Footer/>
     

    {/*<div className="container">

       <div className="rounded shadow pl-1 pt-1 pb-3 pr-3 border border-top-0 d-flex flex-row">
       <div class="card w-50 border-0">
       <div class="card-body">
       <div class="card-title "><h4>Input</h4></div>

       <div className="border"><TabsContainer panelClassName="md-grid" >
    <Tabs tabId="simple-tab" className="bg-white">
      <Tab label="Text" className="border-bottom">
      
      <div class="form-group w-100">
        <textarea class="form-control rounded shadow  user-text align-top user-text" id="comment" onChange={this.onContextChange} placeholder="Type a Passage" value={context}></textarea>
      </div>     
      <input type="text" className="form-control" value={question} onChange={this.onQuestionchange} placeholder="Type a question"/>
         <button className="btn bg-light d-inline-block rounded shadow input-group-text text-dark font-weight-bold mt-3 mx-auto" onClick={this.analyze} type="button" id="btnGroupAddon" >Analyze</button>
      </Tab>
      <Tab label="File" disabled className="ml-5 border-bottom">
        <h3>Now look at me!</h3>
      </Tab>
    </Tabs>
  </TabsContainer></div>

         
       

       </div>
       </div>  

    {result && <div class="card w-50 border-0">
       <div class="card-body">
       <div class="card-title "><h4>Output</h4></div>

       <div className="border p-3 mb-3">
         {answer()}
       </div>

       <h4>Entities</h4>
         {entities}
       </div>
       </div> } 

       </div>     
       
       <Footer/>
        </div> */}  

        </div>
);
}
}

export default QuestionUnderstanding;