
import React, { Component } from 'react';


import '../App.css';
import Footer from './footer/footer.js';

import NER from '../images/services/ner.png';
import QU from '../images/services/qu.jpeg';
import MC from '../images/services/mc.jpg';
import DT from '../images/services/dt.png';
import AL from '../images/services/al.jpg';
import CR from '../images/services/cr.png';
import RE from '../images/services/re.png';
import LTR from '../images/services/ltr.jpg';
import WE from '../images/services/we.jpg';
import LR from '../images/services/lr.png';
import PC from '../images/services/pc.jpg';
import PD from '../images/services/pd.jpg';
import QS from '../images/services/qs.png';
import VT from '../images/services/vt.png';
import LT from '../images/services/lt.jpeg';
import SA from '../images/services/sa.jpg';
import video from '../videos/relation_extraction_demo.mp4';
import SR from '../images/services/sr.png';
import TS from '../images/services/ts.png';
import ADT from '../images/services/auto_doc.png';
import FB from '../images/services/feedback.png';
import SE from '../images/services/se.jpg';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import {
  Button,
  CardActions,
  FileUpload,
  LinearProgress,
  Snackbar,
  TextField,
  FontIcon,
  Checkbox,
  SelectField,
  SelectionControlGroup,
  FileInput
} from 'react-md';


class ServicesDemo extends Component {
  constructor(props) {
    super(props);

    this.state = {
        sentence: "A reusable launch system (RLS, or reusable launch vehicle, RLV) is a launch system which is capable of launching a payload into space more than once. This contrasts with expendable launch systems, where each launch vehicle is launched once and then discarded. No completely reusable orbital launch system has ever been created. Two partially reusable launch systems were developed, the Space Shuttle and Falcon 9. The Space Shuttle was partially reusable: the orbiter (which included the Space Shuttle main engines and the Orbital Maneuvering System engines), and the two solid rocket boosters were reused after several months of refitting work for each launch. The external tank was discarded after each flight.",
        result: null,
        modelNameControls: [],
        selectedModel: []   
    }
}

analyze = () =>{
  const data = {
    model1: {'words': ['Mickey', 'Mouse', 'is', 'funny', '.'], 'tags': ['B-PER', 'L-PER', 'O', 'O', 'O']}, 
    model2: {'words': ['Mickey', 'Mouse', 'is', 'funny', '.'], 'tags': ['B-PER', 'L-ORG', 'O', 'O', 'O']}
  };
  const mNames = Object.keys(data);
      const radioModelNames = mNames.map(function(x) { 
        return { 
          label: x, 
          value: x 
        }; 
      });
      console.log("radioModelNames",radioModelNames);
      console.log("Object.keys(data)[0]",Object.keys(data)[0]);
      this.setState({modelNameControls : radioModelNames,result:data,selectedModel:[Object.keys(data)[0]]});
};

modelChange = (value) => {
  console.log("modelChangevalue",value);
  const selvalue = value.split(",");
  console.log("selvalue",selvalue);
  this.setState({selectedModel : selvalue});
}  
    
    render() {
      
      
      
      /*mNames.reduce((result,model)=>{
        console.log("result",result);
        result = [];
        result.push({"label" : model,
        "key": model},)
       return [result]
      });*/
    
    
    
    return (
      <div>
    <div className="container-fluid">

    <div className="bg-white p-3 pl-3 pr-3 pt-3 rounded border-bottom border-top-0 clearfix d-flex">
    
    <div className="w-30 pr-3 d-flex flex-column">
    <h1 className="d-inline-block pb-2 text-dark">{this.props.details.title}</h1>
    <h5 className="pt-2">
    {this.props.details.content}
    </h5>
    {/*<img src={video} alt="video" width="100%" className="pr-3 mt-1 pb-1"/>*/}
    {/*<video class="video-container video-container-overlay" autoPlay={false}  controls={true} width="400" height="250">
  <source type="video/mp4"  src={video}/>
</video>*/}
<iframe width="400" height="250" src="https://www.youtube.com/embed/b3lHMALNtiI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    <div className="d-flex flex-row">
    {/*<Link to={this.props.details.compLink}  className={`rx-auto d-inline-block ${this.props.details.compLink.length===0 ? 'disabled-link' : ''}`}>
    <Button className="mt-4 ml-2 rounded-pill" raised primary>View Demo</Button></Link>*/}
     <a href={this.props.details.apiUrl} className={`${this.props.details.image.toLowerCase() === 'fs' || this.props.details.image.toLowerCase() === 'qs' ||  this.props.details.image.toLowerCase() === 'pd' || this.props.details.image.toLowerCase() === 'vt' || this.props.details.image.toLowerCase() === 'ltr' ? 'disabled-link' : ''}`} target="blank"><Button className="mt-4 ml-2 d-inline-block rounded-pill" raised primary>API Reference</Button></a>
    <a className="mt-4 ml-2 d-inline-block rounded-pill btn md-background--primary text-white" href={this.props.details.factSheetUrl} target="blank">DOWNLOAD FACT SHEET</a>    
    </div>
 {/*<div className="d-flex flex-row">
   
  </div>*/}
    </div>
    <div className="w-70 float-right">
    <img src={this.props.details.image.toUpperCase() === 'NER' ? NER : this.props.details.image.toUpperCase() === 'VT' ? VT : this.props.details.image.toUpperCase() === 'LT' ? LT : this.props.details.image.toUpperCase() === 'MC' ? MC : this.props.details.image.toUpperCase() === 'QU' ? QU : this.props.details.image.toUpperCase() === 'DT' ? DT : this.props.details.image.toUpperCase() === 'SA' ? SA : this.props.details.image.toUpperCase() === 'AL' ? AL : this.props.details.image.toUpperCase() === 'CR' ? CR : this.props.details.image.toUpperCase() === 'RE' ? RE : this.props.details.image.toUpperCase() === 'WE' ? WE : this.props.details.image.toUpperCase() === 'SR' ? SR : this.props.details.image.toUpperCase() === 'WE' ? WE : this.props.details.image.toUpperCase() === 'LR' ? LR : this.props.details.image.toUpperCase() === 'PC' ? PC : this.props.details.image.toUpperCase() === 'TS' ? TS : this.props.details.image.toUpperCase() === 'ADT' ? ADT : this.props.details.image.toUpperCase() === 'FB' ? FB : this.props.details.image.toUpperCase() === 'SE' ? SE : this.props.details.image.toUpperCase() === 'LTR' ? LTR : this.props.details.image.toUpperCase() === 'PD' ? PD : this.props.details.image.toUpperCase() === 'QS' ? QS : ""} alt={this.props.details.image} className="service-img float-right"/></div>
     

        </div>
<h3 className="ml-3 pt-2">Features</h3>
        
        <div class="card-deck mt-3 border-0 ml-0">
        {this.props.details.features.map((feature)=>(
           <div class="card border-0">
    
           <div class="card-body pl-0">
            
             <p class="card-text">{feature.featureDesc}</p>
            
           </div>
         </div>
        ))}  

        <div className="card border-right-0 border-top-0 border-bottom-0 border-left">

        </div>
 
        </div>

        
       
       
 </div> 
 <div className="">
 <Footer/>
          </div>  
 </div>
);
}
}
export default ServicesDemo;