
import React, { Component } from 'react';
import '../../App.css';
import Footer from '../footer/footer.js';
import MC from '../../images/services/mc.jpg';
import { TabsContainer, Tabs, Tab } from 'react-md';
import axios from 'axios';
import MDSpinner from "react-md-spinner";
import TextHighlight from 'react-text-highlight';
import {
  Button,
  CardActions,
  FileUpload,
  LinearProgress,
  Snackbar,
  TextField,
  FontIcon,
  Checkbox,
  SelectField,
  SelectionControlGroup,
  FileInput
} from 'react-md';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as apiConstants from '../../service-api-constants.js';

class MachineComprehension extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            context: '',
            question: '',
            result: null,
            error:'',
            loaded:true,
            menuItems: [{"context" : `The Matrix is a 1999 science fiction action film written and directed by The Wachowskis, starring Keanu Reeves, Laurence Fishburne, Carrie-Anne Moss, Hugo Weaving, and Joe Pantoliano. It depicts a dystopian future in which reality as perceived by most humans is actually a simulated reality called "the Matrix", created by sentient machines to subdue the human population, while their bodies' heat and electrical activity are used as an energy source. Computer programmer "Neo" learns this truth and is drawn into a rebellion against the machines, which involves other people who have been freed from the "dream world."`,"question":"Who stars in The Matrix?"},
            {"context":`The institutional framework of Navarre was preserved following the 1512 invasion. Once Ferdinand II of Aragon died in January, the Parliament of Navarre gathered in Pamplona, urging Charles V  to attend a coronation ceremony in the town following tradition, but the envoys of the Parliament were met with the Emperor's utter indifference if not contempt. He refused to attend any ceremony and responded with a brief "let's say I am happy and  pleases me." Eventually the Parliament met in 1517 without Charles V, represented instead by the Duke of Najera pronouncing an array of promises of little certitude, while the acting Parliament kept piling up grievances and demands for damages due to the Emperor, totalling 67—the 2nd Viceroy of Navarre Fadrique de Acuña was deposed in 1515 probably for acceding to send grievances. Contradictions inherent to the documents accounting for the Emperor's non-existent oath pledge in 1516 point to a contemporary manipulation of the records.`,"question":'Who represented the Charles V at Parliament?'},
            {"context":`Hoping to rebound from their loss to the Patriots, the Raiders stayed at home for a Week 16 duel with the Houston Texans.  Oakland would get the early lead in the first quarter as quarterback JaMarcus Russell completed a 20-yard touchdown pass to rookie wide receiver Chaz Schilens.  The Texans would respond with fullback Vonta Leach getting a 1-yard touchdown run, yet the Raiders would answer with kicker Sebastian Janikowski getting a 33-yard and a 30-yard field goal.  Houston would tie the game in the second quarter with kicker Kris Brown getting a 53-yard and a 24-yard field goal. Oakland would take the lead in the third quarter with wide receiver Johnnie Lee Higgins catching a 29-yard touchdown pass from Russell, followed up by an 80-yard punt return for a touchdown.  The Texans tried to rally in the fourth quarter as Brown nailed a 40-yard field goal, yet the Raiders' defense would shut down any possible attempt.`,"question":"How many touchdowns were there in the game?"}]
        }
    }

    exampleSelect = (value,i,event) => {
        console.log("value",value);
        let question = this.state.menuItems[i].question;
        this.setState({result: null,context: value,question:question,error:''});
      };

    onContextChange = (value) => {let cont = value; this.setState({context:cont});};
    onQuestionchange = (value) => {let ques = value; this.setState({question:ques});};
    analyze = () => {
        this.setState({loaded:false,result:null});
        /*var reqFormData = new FormData();
        reqFormData.set('context', this.state.context);
        reqFormData.set('question', this.state.question);
        axios({
            method: 'post',
            url: 'http://delcmplcapdb:5000/bidaf',
            data: reqFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            })*/
            axios.post(apiConstants.MACHINECOMPREHENSION, [{"passage" : this.state.context,"question" : this.state.question}],
    {headers: {'Content-Type': 'application/json' }}
      ) 
            .then((response) => {
                console.log("response", response);
                this.setState({loaded:true,result:response.data[0]});
            })
            .catch((error) => {
               let res = [
                {
                    "ans_NERTags": [
                        "PRODUCT",
                        "ORG"
                    ],
                    "ans_NERTagsMap": {
                        " ": "ORG",
                        "Click": "PRODUCT",
                        "InvestNow": "ORG",
                        "Login to NetBanking  Go to Mutual Fund": "ORG"
                    },
                    "ans_phrase": "Login to NetBanking  Go to Mutual Fund tab  Click on InvestNow banner and youll be redirected to the InvestNow platform",
                    "ans_sent": "How to avail InvestNow service Login to NetBanking  Go to Mutual Fund tab  Click on InvestNow banner and youll be redirected to the InvestNow platform.",
                    "next_sent": "",
                    "next_sent_NERTags": [],
                    "prev_sent": "",
                    "prev_sent_NERTags": []
                }
            ];
                console.log("error", error);
                this.setState({loaded:true,result:res[0]});

            });
    }
    
    render() {
    const {result,context,question} = this.state;
    const answer = () => {
        if(result != null){
   
    return <TextHighlight
    highlight={result.ans_phrase}
    text={result.ans_sent}
  />
    }
    else{
        return "";
    }
    }

    const entities = result != null && result.ans_NERTags.map(function(item,index) {
        return (           
            <button key={index}  disabled={true} className="btn btn-outline-secondary rounded text-dark d-inline mr-3 mt-2" >{item}</button>
        );
    });


    return (

        <div className="container-fluid pl-0 pr-0 ">
        <div className="d-flex flex-row w-100">
        <div className="w-10 p-5">
        <Link to="/offerings/machine-comprehension"  className="fa fa-arrow-left float-left pl-2 mx-auto"> Back</Link>
          </div>
          <div className="pl-3 pt-4 pb-5 pr-3  w-90">  
            <h2 className="pt-3 pl-3">Machine Comprehension</h2>
           <div class="card w-100 border-0">
           <div class="card-body pt-2">
           <div className="d-flex flex-row">
           <span className="d-inline-block align-self-end" style={{"whiteSpace":"nowrap","lineHeight":"55px","fontSize":"14px"}}>Enter text or </span>
           <SelectField
          id="mc_example"
          placeholder="Choose an example..."
          className="md-cell d-block w-100"
          
          menuItems={this.state.menuItems}    
          itemValue='context'
          data-text='question'
          onChange={this.exampleSelect} 
          value={this.state.context}
        />
          </div>
          <TextField
          id="floating-center-title"
         label="Context"
          lineDirection="center"
          placeholder="Type a context"
          className="text-field w-100"
          value={this.state.context}
          rows={1}
          onChange={this.onContextChange}
        />
        
            <div className="d-flex flex-row"><TextField
          id="floating-center-title"
         label="Question"
          lineDirection="center"
          placeholder="Type a question"
          className="text-field mt-3"
          value={this.state.question}
          rows={1}
          onChange={this.onQuestionchange}
        />
        <Button className="mt-4 ml-3 align-self-end mb-1 rounded-pill" onClick={this.analyze} raised primary>Analyze</Button>
         </div>
           
    
           </div>
           </div>  
           {!this.state.loaded ? <MDSpinner singleColor="#03a9f4" className="m-5" size={70} borderSize={5}/> : ""}
           {result && <div class="card w-100 border-0">
       <div class="card-body">
       <div class="card-title "><h4 className="font-weight-bold">Answer</h4></div>

       <div className="border p-3 mb-3">
         {answer()}
       </div>

       <h4 className="font-weight-bold">Entities</h4>
         {entities}
       </div>
       </div> } 
    
           {
             this.state.error.length > 0 ? <span className="text-danger pl-4">{this.state.error}</span> : ""
           }
    
           </div>  
           </div>
           <Footer/>
     

    {/*<div className="container">

       <div className="rounded shadow pl-1 pt-1 pb-3 pr-3 border border-top-0 d-flex flex-row">
       <div class="card w-50 border-0">
       <div class="card-body">
       <div class="card-title "><h4>Input</h4></div>

       <div className="border"><TabsContainer panelClassName="md-grid" >
    <Tabs tabId="simple-tab" className="bg-white">
      <Tab label="Text" className="border-bottom">
      
      <div class="form-group w-100">
        <textarea class="form-control rounded shadow  user-text align-top user-text" id="comment" onChange={this.onContextChange} placeholder="Type a Passage" value={context}></textarea>
      </div>     
      <input type="text" className="form-control" value={question} onChange={this.onQuestionchange} placeholder="Type a question"/>
         <button className="btn bg-light d-inline-block rounded shadow input-group-text text-dark font-weight-bold mt-3 mx-auto" onClick={this.analyze} type="button" id="btnGroupAddon" >Analyze</button>
      </Tab>
      <Tab label="File" disabled className="ml-5 border-bottom">
        <h3>Now look at me!</h3>
      </Tab>
    </Tabs>
  </TabsContainer></div>

         
       

       </div>
       </div>  

    {result && <div class="card w-50 border-0">
       <div class="card-body">
       <div class="card-title "><h4>Output</h4></div>

       <div className="border p-3 mb-3">
         {answer()}
       </div>

       <h4>Entities</h4>
         {entities}
       </div>
       </div> } 

       </div>     
       
       <Footer/>
        </div> */}  

        </div>
);
}
}
export default MachineComprehension;