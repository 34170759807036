
import React, { Component } from 'react';

import '../../App.css';
import Footer from '../footer/footer.js';

import axios from 'axios';
import MDSpinner from "react-md-spinner";
import {
  Button as MDbutton,
  CardActions,
  FileUpload,
  LinearProgress,
  Snackbar,
  Divider,
  TextField,
  FontIcon,
  Checkbox,
  SelectField,
  SelectionControlGroup,
  FileInput,
  SVGIcon,Card, CardTitle, CardText, Slider,
  ExpansionList, ExpansionPanel
} from 'react-md';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as apiConstants from '../../service-api-constants.js';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});

function getSteps() {
  return ['Upload Project Credentials', 'Enter a question','validate'];
}

class ActiveLearning extends Component {
  constructor(props) {
    super(props);

    this.state = {
        projectId: "weather-f3807",
        sentence: "",
        result: null,
        modelNameControls: [],
        selectedModel: [],
        error:'',
        loaded:true,
        file:null,
        activeStep: 0,
        question: "how is the weather in delhi",
        similarQuestions:[],
        alResult:[],
        trainResult:"",
        threshold:0.4,
        score:0,
        thumbsColor:"",intentColor: "",thumbsClass:""
    }
}

uploadCredentials = (e) =>{
    this.setState({result: null,loaded:false,error:''});
    var reqFormData = new FormData();        
        reqFormData.set('file', this.state.file);
        reqFormData.set('projectId', this.state.projectId);
        axios({
            method: 'post',
            url: apiConstants.ACTIVELEARNING_UPLOADCREDENTIALS,
            data: reqFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            }).then((response) => {
                console.log("response", response);
                this.setState({loaded:true,result:response.data});
            })
            .catch((error) => {              
                console.log("error", error);
                this.setState({loaded:true,result:"File Saved",error:error.toString()});

            });
};

fileChange = (files,e) => {
  console.log("files",files);
  this.setState({file:files});
};

analyze = (e) =>{
    this.setState({result: null,loaded:false,error:''});        
        axios.post(apiConstants.ACTIVE_LEARNING, {query: this.state.question,
          projectId: this.state.projectId},
    {headers: {'Content-Type': 'application/json' }}
      )  
  .then((res) => {
    console.log(res);
    const data = res.data;   
    this.setState({alResult:data,loaded:true});    
  }).catch((error) => {
        console.log("error",error);
        
        this.setState({error: error.toString(),loaded:true});
    });
};

similarQuestions = () =>{ 
  var reqFormData = new FormData();        
      reqFormData.set('text', this.state.question);     
      axios({
          method: 'post',
          url: apiConstants.SIMILAR_QUESTIONS,
          data: reqFormData,
          config: { headers: {'Content-Type': 'multipart/form-data' }}
          }).then((response) => {
              console.log("response", response);
              this.setState({loaded:true,similarQuestions:response.data});
          })
          .catch((error) => {              
              console.log("error", error);
              this.setState({loaded:true,error:error.toString()});

          });
};

trainDfModel = () =>{
  this.setState({result: null,loaded:false,error:''});
  const qnsToTrain = this.state.similarQuestions;  
  qnsToTrain.unshift(this.state.question);      
      axios.post(apiConstants.TRAIN_DF_MODEL, {intentname: this.state.alResult.maxvote[0].intent_class,phrases:qnsToTrain,
        projectId: this.state.projectId},
  {headers: {'Content-Type': 'application/json' }}
    )  
.then((res) => {
  console.log(res);
  const data = res.data;   
  this.setState({trainResult:data,loaded:true});    
}).catch((error) => {
      console.log("error",error);
      
      this.setState({error: error.toString(),loaded:true});
  });
};

thresholdChange = (e) => {
  const thresholdValue = e.target.value;
  if(thresholdValue <= this.state.score){
    this.setState({threshold:thresholdValue,thumbsColor:"green",intentColor: "green",thumbsClass:"fa fa-thumbs-up"});
   
    }
    else{
      this.setState({threshold:thresholdValue,thumbsColor:"red",intentColor: "red",thumbsClass:"fa fa-thumbs-down"});
    }
  
}

modelChange = (value,e) => {
    console.log("modelChangevalue",value);
    const items = this.state.modelNameControls;
      const selModel = this.state.selectedModel;
    if(value){
      
      items.map((itm)=>{
        if(itm.value == e.target.value){
          itm.checked = value;
        }
      });
  
     
  
  if (selModel.indexOf(e.target.value) === -1) selModel.push(e.target.value);
  
      this.setState({selectedModel : selModel,modelNameControls:items});
    }else{   
      items.map((itm)=>{
        if(itm.value == e.target.value){
          itm.checked = value;
        }
      });
  
      var index = selModel.indexOf(e.target.value);
      if (index > -1) {
        selModel.splice(index, 1);
      }
      this.setState({selectedModel : selModel,modelNameControls:items});
    }
    
  };  

  projectIdChange = (value) => {
  this.setState({projectId: value});
};

questionChange = (value) => {
   this.setState({question:value});
};

exampleSelect = (value) => {
  this.setState({sentence: value});
};

handleLoad = ({ name, size }) => {
    this.progressTimeout = setTimeout(() => {
      this.progressTimeout = null;
      this.setState({ progress: null });
    }, 500);
    this.setState({ fileName: name, fileSize: size });
  };

  handleLoadStart = () => {
    this.setState({ progress: 0 });
  };

  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };
  getStepContent = (step) => {
    switch (step) {
      case 0:
        return <div className="d-flex flex-row  p-4">
        <div className="w-10"><TextField
      id="floating-center-title"
     label="Project Id"
      lineDirection="center"
      placeholder="Enter Project Id"
      className="text-field mt-3"
      value={this.state.projectId}
      rows={1}
      onChange={this.projectIdChange}
    /></div>

   <div className="align-self-center ml-2">
   <FileUpload
          id="server-upload-file"
          label="Choose file"
          onLoad={this.handleLoad}
          onLoadStart={this.handleLoadStart}
          required
          accept="image/*,video/*"
          name="file"
          className="file-inputs__upload-form__file-upload ml-3 mr-3 rounded-pill"
          onChange={this.fileChange}
          icon={null}
          primary
          iconBefore
        />
        <TextField
          id="server-upload-file-field"
          placeholder="No file chosen"
          value={this.state.fileName}
          className="file-inputs__upload-form__file-field"
          readOnly
          fullWidth={false}
        />
        
        <MDbutton className="mt-4 ml-3 align-self-end mb-1 rounded-pill" iconEl={<FontIcon iconClassName="fa fa-arrow-circle-up" />} disabled={!this.state.fileName || !this.state.loaded} onClick={this.uploadCredentials} raised primary>Upload</MDbutton>
        
        

        </div>
        
        </div>;
      case 1:
        return <div className="pl-4">
              <TextField
        id="question"
        label="question"
        type="text"
        inlineIndicator={<MDbutton className="md-text--theme-primary" iconEl={<FontIcon iconClassName="fa fa-search" />} onClick={this.analyze}></MDbutton>}        
        fullWidth={false}
        className="w-31 pr-4"
        rows={1}
        value={this.state.question}
        onChange={this.questionChange}
      />          
        </div>;
      case 2:
        return <div className="pl-4">
        <TextField
  id="question_validate"
  label="question"
  type="text"
  inlineIndicator={<MDbutton className="mb-1 rounded-pill" onClick={this.analyze} raised primary>Validate</MDbutton>}        
  fullWidth={false}
  className="w-31 pr-4"
  rows={1}
  value={this.state.question}
  onChange={this.questionChange}
/>          
  </div>;
      default:
        return 'Unknown step';
    }
  }
    
    render() {
      const { classes } = this.props;
      const steps = getSteps();
      const { activeStep, result } = this.state;
      
    return (
    <div className="container-fluid pl-0 pr-0 ">
    <div className="d-flex flex-row w-100">
    <div className="w-10 p-5">
    <Link to="/offerings/active-learning"  className="fa fa-arrow-left float-left pl-2 mx-auto"> Back</Link>
      </div>
      <div className="pl-3 pt-4 pb-5 pr-3  w-90">  
        <h2 className="pt-3 pl-3">Active Learning</h2>
       <div class="card w-100 border-0">
       <div class="card-body pt-2">
       
        
       <Stepper activeStep={activeStep} orientation="vertical" className="stepper p-0">
          {steps.map((label, index) => (
            <Step key={label} >
              <StepLabel><strong>{label}</strong></StepLabel>
              <StepContent>
                <Typography>{this.getStepContent(index)}</Typography>
                <div className="ml-4">
                {index == 0 ? this.state.result != null && this.state.result == 'File Saved' ? <div className="text-success font-weight-bold"><i className="fa fa-check"></i> File saved Successfully</div> : this.state.result != null && this.state.result.length > 0 ? <div className="text-danger font-weight-bold"><i className="fa fa-times"></i> {this.state.result}</div> : "" : ""}
                {!this.state.loaded ? <MDSpinner singleColor="#03a9f4" className="m-5" size={50} borderSize={5}/> : ""}
                {index == 1  ? <div className="d-flex flex-row mt-3"><Card className="w-30">
    <CardTitle title="DialogFlow Model" />
    <CardText>
     <table className="df-table" >
     <tbody>
         <tr>
           <td colSpan="3"><h3>Before Training</h3></td>           
        </tr>
        <tr>
        <td>Mapped Intent</td>
        <td>:</td>
        <td>No Matching intent found</td>
        </tr>
        <tr>
        <td>Confidence Score</td>
        <td>:</td>
        <td>0.00</td>
        </tr>       
     </tbody>
     </table>
    </CardText>
  </Card> 

  <Card className="w-70 ml-4">
    <CardTitle title="Learning Models" />
    
    <CardText>
     <div className="d-flex flex-row"><table className="df-table table w-30">
     <tbody>
         <tr>
           <th>Model Name</th>
           <th>Mapped Intent</th>
           <th>Confidence Score</th>           
        </tr>
        <tr>
        <td className="text-center">SGD</td>
        <td className="text-center">weather.condition</td>
        <td className="text-center">0.48</td>
        </tr>
        <tr>
        <td className="text-center">RF</td>
        <td className="text-center">weather.condition</td>
        <td className="text-center">0.48</td>
        </tr>
        <tr>
        <td className="text-center">RF</td>
        <td className="text-center">weather.condition</td>
        <td className="text-center">0.48</td>
        </tr>
     </tbody>
     </table>
     <Divider className="bg-dark" vertical/>
     <table className="df-table table-borderless w-70 ml-5" >
     <tbody>
         <tr>
           <td colSpan="3"><h3>Outcome</h3></td>           
        </tr>
        <tr>
        <td style={{"width":"200px"}}>Threshold</td>
        <td style={{"width":"20px"}}>:</td>
        <td>
          <input  type="number" min="0.0" max="1.0" value={this.state.threshold} onChange={this.thresholdChange} step="0.1" style={{"width":"40px"}}/>
        </td>
        </tr>
        <tr>
        <td>Recommended Intent</td>
        <td >:</td>
        <td>
          <span id="intent" style={{"color": `${this.state.intentColor}`}}>hgfhfh</span>&nbsp;&nbsp;
          <i id="thumbs-up" className={`${this.state.thumbsClass}`} style={{"color": `${this.state.thumbsColor}`}} ></i>
        </td>
        </tr>
        <tr>
        <td>Confidence Score</td>
        <td>:</td>
        <td>0.00</td>
        </tr>
       
     </tbody>
     </table>
     
     
     </div>
     <div className="d-flex flex-row">
     <ExpansionList className="w-40">
     <ExpansionPanel label="Show similar questions" footer={null}>
        <ul>
          <li>1</li>
          <li>2</li>
          <li>3</li>
          </ul>
      </ExpansionPanel>
      </ExpansionList>
      <div className="w-60 ">
      <MDbutton className="mt-4 ml-3 align-self-end mb-1 rounded-pill" onClick={this.trainDfModel} raised primary>Train dialog flow model</MDbutton>
      
        </div>
    </div>
    </CardText>
    
  </Card>

  </div> : ""}
  {index == 2  ? <div className="d-flex flex-row mt-3">
  
  <Card className="w-30">
    <CardTitle title="DialogFlow Model" />
    <CardText>
     <table className="df-table" >
     <tbody>        
        <tr>
           <td colSpan="3" className="pt-4"><h3>After Training</h3></td>           
        </tr>
        <tr>
        <td>Mapped Intent</td>
        <td>:</td>
        <td>No Matching intent found</td>
        </tr>
        <tr>
        <td>Confidence Score</td>
        <td>:</td>
        <td>0.00</td>
        </tr>
     </tbody>
     </table>
    </CardText>
  </Card>
  </div> : ""}
             {
         this.state.error.length > 0 ? <div className="text-danger font-weight-bold">{this.state.error}</div> : ""
       }
                  <div className="mt-3">
                    <Button
                      disabled={activeStep === 0}
                      onClick={this.handleBack}
                      
                      className="md-background--primary mr-2 rounded-pill text-white"
                    >
                      Back
                    </Button>
                    {activeStep < steps.length - 1 ? <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className="md-background--primary rounded-pill"
                    >
                       Next
                    </Button> : ""}
                    {activeStep === steps.length - 1 ? <Button onClick={this.handleReset}  className="text-white md-background--primary rounded-pill">
              Done
            </Button> : ""}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
       
   
    
       

       </div>
       </div>  
      

       </div>  
       
       </div>
       <Footer/>
 </div>   
);
}
}

export default ActiveLearning;