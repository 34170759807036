// export const NODE_PROXY_URL = 'http://18.217.230.83:8081';
const DNS = `https://rjw9gxqtnj.execute-api.us-west-1.amazonaws.com/dev/user`;
const NODE_SERVER_URL = `${DNS}/node`;
//export const NODE_PROXY_URL = `https://42ba59a9d7a.autokaas.com/node`

// endpoints called directly (without /node):
export const VERIFY_USER_LOGIN = `${DNS}/login`;

// endpoints called via node server:
export const GETKNOWLEDGEBASES = `${NODE_SERVER_URL}/getKnowledgeBases`;
export const SEARCH_RESULTS = `${NODE_SERVER_URL}/semanticSearch`;
// export const NERSERVICEURL =  `${NODE_PROXY_URL}/ner`;
export const NERSERVICEURL =  `${NODE_SERVER_URL}/ner/docs`;
export const ENHANCEDNERURL =  `${NODE_SERVER_URL}/enhancedNER`;
export const QUESTIONUNDERSTANDING = `${NODE_SERVER_URL}/questionunderstanding`;
export const PHRASECOMPLETION = `${NODE_SERVER_URL}/phraseCompletion`;
export const MACHINECOMPREHENSION = `${NODE_SERVER_URL}/machinecomprehension`;
export const CURIOSUMWEBSITEURL = "https://hackathon.autokaas.com/";
export const CHATBOTURL = "https://hackathon.autokaas.com/chatbot";
export const RELATION_EXTRACTION = 'http://10.202.7.238:8086/getTriples';
export const ACTIVELEARNING_UPLOADCREDENTIALS = 'http://ec2-18-188-218-182.us-east-2.compute.amazonaws.com:8080/uploadcredentials';
export const COREFERENCE_RESOLUTION = 'http://delvmchat:5020/coreferences';
export const ACTIVE_LEARNING = "http://ec2-18-188-218-182.us-east-2.compute.amazonaws.com:8080/activelearning";
export const SIMILAR_QUESTIONS = "http://ec2-18-188-162-43.us-east-2.compute.amazonaws.com:8080/paragen";
export const TRAIN_DF_MODEL = "http://delcmplcapdb.sapient.com:5555/updateintent";
export const LEARN_TO_RANK = "http://10.207.16.194:5016/reranking";
export const WORDEMBEDDING = `${NODE_SERVER_URL}/wordEmbedding`;
export const TEXT_SUMMARIZATION = `${NODE_SERVER_URL}/textSummarization`;
export const AUTO_DOCUMENT_TAGGING = `${NODE_SERVER_URL}/automatedTagging`;
export const SUBMIT_FEEDBACK = `${NODE_SERVER_URL}/feedbackNode`;

