
import React, { Component } from 'react';
import '../../App.css';
import Footer from '../footer/footer.js';
import KaasFactSheet from '../../images/Knowledge as a Service.pdf';
// import KaasMainDeck from '../../images/KaaS_feb_2020.pdf';
// import KaasVideo from '../../images/KaaS_ Cognitive Search.mp4';
import KaasIntegration from '../../images/cognitive_search integration.pdf';
import ServicesDeployment from '../../images/Services_Deployment.pdf';
import Scalability from '../../images/Scalability.pdf';
import ServicesList from '../../images/Services_list.pdf';

class Documents extends Component {
    

    render() {
        var KaasVideo = 'https://curiosum-html-data.s3.us-east-2.amazonaws.com/KaaS_Cognitive_Search.mp4'
        var KaasMainDeck = 'https://curiosum-html-data.s3.us-east-2.amazonaws.com/KaaS_feb_2020.pdf'
    return (
    <div>
        <div className="container-fluid pl-0 pr-0">
           <div className="bg-dark text-white pt-4 pl-4 pb-2">
           <h1 className="d-block pl-3 text-white offerings-header">KaaS - Documents</h1></div>
        </div>
    <div className="container-fluid mt-3 pt-3 pl-5  pb-3 bg-light ">
    <h2>Product Documentation</h2>
    <ul className="docs-list text-primary ml-0 pl-3">
    <li><a href={KaasFactSheet} target="blank">Fact Sheet</a></li>

<li><a href={KaasMainDeck} target="blank">KaaS Main Deck</a></li>

<li><a href={KaasVideo} target="blank">KaaS Video</a></li>

<li><a href={ServicesList} target="blank">KaaS Services List</a></li>

<li><a href={ServicesDeployment} target="blank">KaaS Services Deployment</a></li>
<li><a href={Scalability} target="blank">KaaS Scalability</a></li>
<li><a href={KaasIntegration} target="blank">KaaS cognitive search integration with keyword match search engines</a></li>
</ul>
 
    </div>
    <div className="">
 <Footer/>
          </div>  
 </div>   
);
}
}
export default Documents;