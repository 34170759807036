
import React, { Component } from 'react';
import '../../App.css';
import Footer from '../footer/footer.js';
import Ensemble from '../../images/ensemble.jpg';


class Blogs extends Component {
    
    render() {
    
    return (
    <div>
        <div className="container-fluid pl-0 pr-0">
           <div className="bg-dark text-white pt-4 pl-4 pb-2">
           <h1 className="d-block pl-3 text-white offerings-header">KaaS - Articles</h1></div>
        </div>
    <div className="container-fluid mt-3  pb-3 bg-light d-flex flex-row">
    <div className="card-deck pl-4">
  <div class="card">
  <a href="https://towardsdatascience.com/distillation-of-knowledge-in-neural-networks-cc02f79698b6" target="blank"><img class="card-img-top" src={Ensemble} alt="Card image"/></a>
  <div class="card-body">
    <h4 class="card-title font-weight-bold">Distillation of Knowledge in Neural Networks - Towards Data Science</h4>
    <p class="card-text"><span className="font-weight-bold">Distillation of Knowledge</span> (in machine learning) is an architecture agnostic approach for generalization of knowledge (consolidating the knowledge) within a neural network to train another neural network.</p>
    <a href="https://towardsdatascience.com/distillation-of-knowledge-in-neural-networks-cc02f79698b6" target="blank" class="fa fa-arrow-right float-right"></a>
  </div>
  </div>
  <div class="card invisible">
  
  </div>
  <div class="card invisible">
  
  </div>
  <div class="card invisible">
  
  </div>
  </div>
    </div>
    <div className="">
 <Footer/>
          </div>  
 </div>   
);
}
}
export default Blogs;