
import React, { Component } from 'react';
import logo from '../../images/logo.JPG';
import kaasLogo from '../../images/kaas_logo.JPG';
import '../../App.css';
import Footer from '../footer/footer.js';

import QU from '../../images/services/qu.jpeg';
import MC from '../../images/services/mc.jpg';
import AL from '../../images/services/al.jpg';
import NER from '../../images/services/ner.png';
import DT from '../../images/services/dt.png';
import CR from '../../images/services/cr.png';
import RE from '../../images/services/re.png';
import LTR from '../../images/services/ltr.jpg';
import WE from '../../images/services/we.jpg';
import SR from '../../images/services/sr.png';
import LR from '../../images/services/lr.png';
import PC from '../../images/services/pc.jpg';
import TS from '../../images/services/ts.png';
import ADT from '../../images/services/auto_doc.png';
import FB from '../../images/services/feedback.png';
import SE from '../../images/services/se.jpg';
import QS from '../../images/services/qs.png';
import PD from '../../images/services/pd.jpg';
import LT from '../../images/services/lt.jpeg';
import VT from '../../images/services/vt.png';
import SA from '../../images/services/sa.jpg';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';


class Offerings extends Component {

  state={
    openGeneral:true,
    openAvailable:false
  };

  handleGeneralClick = () => {
    this.setState({openGeneral:!this.state.openGeneral});
  };

  handleAvailableClick = () => {
    this.setState({openAvailable:!this.state.openAvailable});
  };
    
    render() {
    
    return (
    <div>
        <div className="container-fluid pl-0 pr-0">
           <div className="bg-dark text-white pt-4 pl-4 pb-2"><h1 className="d-block pl-3 text-white offerings-header">KaaS Cognitive Services {/*<Link to="/swaggerui" ><button className="btn btn-primary float-right mt-2 mr-5 md-background--primary md-background--primary-hover">API Reference</button></Link>*/}</h1></div>
        </div>
    <div className="container-fluid mt-3  pb-3 bg-light d-flex flex-row">
      <div className="w-20">    
  <div className="card border-0 bg-light">  
  <div className="card-body">
  <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className=""
    >
       <ListItem button onClick={this.handleGeneralClick} className="service-item-title mb-1">
       
        <ListItemText primary="Generally Available Services" className="font-weight-bold"/>
        {this.state.openGeneral ? <span><i className="fa fa-angle-up"></i></span> : <span><i className="fa fa-angle-down"></i></span>}
      </ListItem>
      <Collapse in={this.state.openGeneral} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className="">
           
          <Link to="/offerings/machine-comprehension" className="service-item-name">Machine Comprehension</Link>
          </ListItem>
          <ListItem button className="">
           
          <Link to="/offerings/answer-type" className="service-item-name">Answer Type</Link>
           </ListItem>
           <ListItem button className="">
           
           <Link to="/offerings/section-extraction" className="service-item-name">Section Extractor</Link>
           </ListItem>
           <ListItem button className="">          

           <Link to="/offerings/triplet-extraction" className="service-item-name">Triplet Service</Link>
           </ListItem>
           <ListItem button className="">
           
           <Link to="/offerings/coreference-resolution" className="service-item-name">Coreference Resolution</Link>
           </ListItem>
           <ListItem button className="">
           
           <Link to="/offerings/phrase-completion" className="service-item-name">Phrase Completion</Link>
           </ListItem>
           <ListItem button className="">
           
           <Link to="/offerings/query-suggestion" className="service-item-name">Query Suggestion</Link>
           </ListItem>
           <ListItem button className="">
           
           <Link to="/offerings/paraphrase-detection" className="service-item-name">Paraphrase Detection</Link>
           </ListItem>
           <ListItem button className="">
           
           <Link to="/offerings/named-entity-recognition" className="service-item-name">Named Entity Recognition</Link>
           </ListItem>
           <ListItem button className="">
           
           <Link to="/offerings/automated-document-tagging"  className="service-item-name">Autotagger</Link>
           </ListItem>
           <ListItem button className="">
           
           <Link to="/offerings/semantic-ranking" className="service-item-name">Semantic Ranking</Link>
           </ListItem>
           <ListItem button className="">
           
           <Link to="/offerings/language-translation" className="service-item-name">Translation</Link>
           </ListItem>
           <ListItem button className="">
           
           <Link to="/offerings/text-summarization" className="service-item-name">Text Summarization</Link>
           </ListItem>
           <ListItem button className="">
           
           <Link to="/offerings/word-embedding" className="service-item-name">Word Embedding</Link>
           </ListItem>
           
           <ListItem button className="">
           
           <Link to="/offerings/sentiment-analysis" className="service-item-name">Sentiment Analysis</Link>
           </ListItem>
        </List>
      </Collapse>

<ListItem button onClick={this.handleAvailableClick} className="service-item-title">
       
       <ListItemText primary="On Demand Services" className="font-weight-bold"/>
       {this.state.openAvailable ? <span><i className="fa fa-angle-up"></i></span> : <span><i className="fa fa-angle-down"></i></span>}
     </ListItem>
     <Collapse in={this.state.openAvailable} timeout="auto" unmountOnExit>
       <List component="div" disablePadding>
         <ListItem button className="">
          
         <Link to="/offerings/learn-to-rank" className="service-item-name">Learn To Rank</Link>
         </ListItem>
         <ListItem button className="">
          
          <Link to="/offerings/video-transcription" className="service-item-name">Video Transcription</Link>
          </ListItem>
          <ListItem button className="">
          
          <Link to="/offerings/feedback-service" className="service-item-name">Feedback</Link>
          </ListItem>
         </List>
         </Collapse>

    </List>
  {/* <ul className="listServices">
   <li className="pt-0">Raw Text Extraction</li>

<li>Section Extraction</li>

<li><Link to="/offerings/relation-extraction" >Relation Extraction</Link></li>


<li> <Link to="/offerings/semantic-ranking">Semantic Ranking</Link></li>

<li>Natural Language to Structured Query Language Conversion</li>

<li><Link to="/offerings/coreference-resolution" >Coreference Resolution</Link></li>


<li> </li>

<li>Question Suggestions</li>

<li><Link to="/offerings/question-understanding">Question Understanding</Link></li>

<li>Paraphrase Detection</li>

<li>Proprietary Embeddings</li>

<li>Answers Validation</li>

<li>Entity Resolution</li>

<li>Structured Data Extraction</li>

<li>Slot Tagger</li>




<li><Link to="/offerings/document-tagging">Document Tagging</Link></li>

<li> <Link to="/offerings/named-entity-recognition">Named Entity Recognition</Link></li>
<li> <Link to="/offerings/phrase-completion">Phrase Completion</Link></li>
<li><Link to="/offerings/word-embedding">Word Embedding</Link></li>
<li><Link to="/offerings/text-summarization">Text Summarization</Link></li>
    </ul>*/}

     
   
  </div>
  </div>
  </div>
  <div className="w-80"> 
<div className="card-deck mt-3">

 {/*<div className="card">
  <img className="card-img-top border-bottom" src={DT} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Document Tagging</h6>
    <p className="card-text pt-3">Document Tagging uses Named Entity Recognition is the task of identifying the text of special meaning and classifying into some predetermined categories like person, organisation, location etc but also provides extended capabilities of identifying custom categories like disease, chemical, enzyme, broker, stock value etc.</p>
    <Link to="/offerings/document-tagging"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>
  </div>*/}

  <div className="card">
 <img className="card-img-top border-bottom " src={ADT} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Automated Document Tagging</h6>
    <p className="card-text pt-3">Automated Document tagging  is the automated process of extracting the most relevant words and expressions from text document. It helps to recognize the main topics which are being discussed and help to improve search accuracy.</p>
    <Link to="/offerings/automated-document-tagging"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>

</div>

 

  <div className="card">
  <img className="card-img-top border-bottom" src={QU} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Answer Type</h6>
    <p className="card-text pt-3">KaaS' Answer Type service provides the ability to comprehend the types type/nature of information that could answer a question. This can be leveraged to detect which type of Q&A system would be best-suited to answer the question.</p>
    <Link to="/offerings/answer-type"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>
  </div>
  <div className="card">
  <img className="card-img-top border-bottom" src={NER} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Named Entity Recognition</h6>
    <p className="card-text pt-3">This Service identifies named entities (people, locations, organizations, and miscellaneous) in the input text.</p>
    <Link to="/offerings/named-entity-recognition"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto" ></Link>
  </div>
  </div>  

<div className="card">
  <img className="card-img-top border-bottom" src={MC} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Machine Comprehension</h6>
    <p className="card-text pt-3">Machine Comprehension is used for retrieval of precise answer to a question from a text. The span of the answer could be anything from a word to list of sentences. It can also be used for questions assisted summarisation.</p>
    <Link to="/offerings/machine-comprehension"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>
  </div>
 </div> 

<div className="card-deck mt-3">

  

 <div className="card">
  <img className="card-img-top border-bottom " src={SR} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Semantic Ranking</h6>
    <p className="card-text pt-3">KaaS' Semantic Ranking is a domain-agnostic content recommendation system. KaaS' Semantic Ranking is a powerful, scalable and swift solution to ranking documents or even the paragraphs within, for a given query.</p>
    <Link to="/offerings/semantic-ranking"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>
  </div>

  <div className="card">
  <img className="card-img-top border-bottom " src={FB} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Feedback Service</h6>
    <p className="card-text pt-3">The idea behind feedback is to take the results that are initially returned from a given query, to gather user feedback, and to use information about whether or not those results are relevant to perform a new query.Explicit user Feedback helps Learn to Rank system (LTR) to re-rank result items for a given query</p>
    <Link to="/offerings/feedback-service"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>
  </div>

{/*<div className="card">
  <img className="card-img-top border-bottom " src={AL} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Active Learning</h6>
    <p className="card-text pt-3">KaaS' Active Learning helps to automate re-training of classifier for unlabelled data. Active learning is a very powerful tool which can be used when there is a shortage of labelled data.</p>
    <Link to="/offerings/active-learning"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>
  </div>*/}

  <div className="card">
  <img className="card-img-top border-bottom"  src={CR} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Coreference Resolution</h6>
    <p className="card-text pt-3">It is the task of finding all expressions that refer to the same entity in a text. It is an important step for a lot of higher level NLP tasks that involve natural language understanding such as document summarization, question answering, and information extraction.</p>
    <Link to="/offerings/coreference-resolution"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>
  </div>

  <div className="card">
  <img className="card-img-top border-bottom" src={RE} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Triplet Extraction</h6>
    <p className="card-text pt-3">Extraction of structured relation triples from plain text, such that the schema for these relations does not need to be specified in advance.</p>
    <Link to="/offerings/triplet-extraction"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>
  </div>
 
</div>

<div className="card-deck mt-3">
 <div className="card">
   <img className="card-img-top border-bottom " src={PC} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Phrase Completion</h6>
    <p className="card-text pt-3">Phrase Completion service combines power laws, statistics and linguistic algorithms to provide not just accurate but also a fast and scalable solution to auto-suggest the complete phrase in real-time.</p>
    <Link to="/offerings/phrase-completion"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>
   </div>
 <div className="card">
   <img className="card-img-top border-bottom " src={WE} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Word Embedding</h6>
    <p className="card-text pt-3">Word embedding is the collective name for a set of language modeling and feature learning techniques in natural language processing (NLP) where words or phrases from the vocabulary are mapped to vectors of real numbers.</p>
    <Link to="/offerings/word-embedding"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>
   </div>

<div className="card">
 <img className="card-img-top border-bottom pt-1 pb-1" src={TS} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Text Summarization</h6>
    <p className="card-text pt-3">KaaS' Summarizer is capable of generating human-like summary of a given document. Instead of extracting a few lines from within the text, KaaS' Summarizer is able to write a cohesive and coherent summary. The length of the summary can be adjusted by providing a range of words.</p>
    <Link to="/offerings/text-summarization"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>

</div>

 <div className="card">
  <img className="card-img-top border-bottom" src={SE} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Section Extraction</h6>
    <p className="card-text pt-3">Section Extraction extracts text content from various file formats and sources. </p>
    <Link to="/offerings/section-extraction"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>
  </div>

</div>


<div className="card-deck mt-3">
 <div className="card">
   <img className="card-img-top border-bottom pt-1 pb-1" src={QS} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Query Suggestion</h6>
    <p className="card-text pt-3">Query Suggestions is a search experience that displays a list of possible queries that your users can select from as they type.</p>
    <Link to="/offerings/query-suggestion"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>
   </div>
 <div className="card">
   <img className="card-img-top border-bottom " src={PD} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Paraphrase Detection</h6>
    <p className="card-text pt-3">Paraphrase Detection service is used to quantify the similarity of semantics between two documents/questions/sentences.</p>
    <Link to="/offerings/paraphrase-detection"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>
   </div>

<div className="card">
 <img className="card-img-top border-bottom " src={LTR} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Learn To Rank</h6>
    <p className="card-text pt-3">LTR solves the personalised document ranking problem. The aim of LTR is to come up with optimal ordering of documents, in a manner that is personalised to one or a set of users.LTR has the ability to understand individual preferences and over-time modify search results to best suit those preferences.</p>
    <Link to="/offerings/learn-to-rank"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>

</div>

 <div className="card">
   <img className="card-img-top border-bottom pt-2 pb-1" src={VT} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Video Transcription</h6>
    <p className="card-text pt-3">Video Transcription service can convert speech to text from recorded video files. It can be used to convert audio and video assets into fully searchable archives for highlight generation, compliance monitoring, content consumption analysis, and monetization etc.</p>
    <Link to="/offerings/video-transcription"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>
   </div>

</div>

<div className="card-deck mt-3">

<div className="card">
  <img className="card-img-top border-bottom" src={LT} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Language Translation</h6>
    <p className="card-text pt-3">KaaS Language translation service makes NLP systems compatible with a variety of languages. It converts the inputs and outputs of an NLP system to the language it supports.</p>
    <Link to="/offerings/language-translation"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>
  </div>



<div className="card ">
<img className="card-img-top border-bottom pb-1 pt-1" src={SA} alt="Card image"/>
  <div className="card-body d-flex flex-column">
    <h6 className="card-title border-bottom pb-1 font-weight-bold">Sentiment Analysis</h6>
    <p className="card-text pt-3">Sentiment analysis is the automated process of analyzing text to determine the sentiment expressed (positive, negative or neutral). Some popular sentiment analysis applications include social media monitoring, customer support management, and analyzing customer feedback.</p>
    <Link to="/offerings/sentiment-analysis"  className="fa fa-arrow-right float-right text-right align-self-end mt-auto"></Link>
  </div>

</div>

<div className="card invisible"></div>

<div className="card invisible"></div>


</div>



 
  </div>
    </div>

    <div className="container-fluid pl-0 pr-0">
    <Footer/>
    </div>
 </div>   
);
}
}
export default Offerings;