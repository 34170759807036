
import React, { Component } from 'react';

import logo from '../../images/logo.JPG';
import kaasLogo from '../../images/kaas_logo.JPG';
import '../../App.css';
import Footer from '../footer/footer.js';

import doc1 from '../../docs/document1.txt';
import doc2 from '../../docs/document2.txt';
import doc3 from '../../docs/document3.txt';
import doc4 from '../../docs/document4.txt';
import txt_icon1 from '../../images/services/doc-icon1.png';
import txt_icon2 from '../../images/services/doc-icon2.png';
import txt_icon3 from '../../images/services/doc-icon3.png';
import txt_icon4 from '../../images/services/doc-icon4.png';
import axios from 'axios';
import MDSpinner from "react-md-spinner";
import {
  Button,
  CardActions,
  FileUpload,
  LinearProgress,
  Snackbar,
  TextField,
  FontIcon,
  Checkbox,
  SelectField,
  SelectionControlGroup,
  FileInput
} from 'react-md';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as apiConstants from '../../service-api-constants.js';


class DocumentTagging extends Component {
  constructor(props) {
    super(props);

    this.state = {
        sentence: "",
        result: null,
        modelNameControls: [],
        selectedModel: [],
        error:'',
        loaded:true,
        fileName:'',file:null,uploadedData:''
    }
}

analyzeDoc = () =>{
  this.setState({result: null,loaded:false,error:''});
  axios.post(apiConstants.NERSERVICEURL, {"sentence" : this.state.uploadedData.trim()},
  {headers: {'Content-Type': 'application/json' }}
    )  
.then((res) => {
  console.log(res);
  const data = res.data; 
  if(res.data.code && (res.data.code === 'ECONNREFUSED' || res.data.code === 'ETIMEDOUT')){
    this.setState({error: res.data.code,loaded:true});
  }else{
    const mNames = ["KaaS","SpaCy","AllenNLP"]; //Object.keys(data);
    const radioModelNames = mNames.map((x,index)=> { 
      const check = index == 0 ? true : false;
      return { 
        label: x, 
        value: x,
        checked: check 
      }; 
    });
    console.log("radioModelNames",radioModelNames);
    console.log("Object.keys(data)[1]",Object.keys(data)[1]);
    //this.modelChange(radioModelNames[0].value);
    this.setState({modelNameControls : radioModelNames,result:data,selectedModel:[Object.keys(data)[1]],error:'',loaded:true}); 
  }       
  
  
}).catch((error) => {
      console.log("error",error);
      
      this.setState({error: error.toString(),loaded:true});
  });
}

analyze = (e) =>{
   // this.setState({result: null,loaded:false,error:''});
    axios.get(e.target.id).then((response) => {
        console.log("file content",response.data)
        this.setState({uploadedData:response.data,fileName:'',file:null})
        
     /*   axios.post(apiConstants.NERSERVICEURL, {"sentence" : response.data.trim()},
    {headers: {'Content-Type': 'application/json' }}
      )  
  .then((res) => {
    console.log(res);
    const data = res.data;          
    const mNames = ["KaaS","SpaCy","AllenNLP"]; //Object.keys(data);
    const radioModelNames = mNames.map((x,index)=> { 
      const check = index == 0 ? true : false;
      return { 
        label: x, 
        value: x,
        checked: check 
      }; 
    });
    console.log("radioModelNames",radioModelNames);
    console.log("Object.keys(data)[1]",Object.keys(data)[1]);
    //this.modelChange(radioModelNames[0].value);
    this.setState({modelNameControls : radioModelNames,result:data,selectedModel:[Object.keys(data)[1]],error:'',loaded:true}); 
    
  }).catch((error) => {
        console.log("error",error);
        
        this.setState({error: error.toString(),loaded:true});
    });*/
 /* const data = {'SpaCy': {'text': 'Mickey Mouse lives in Santa Maria.',
  'tags': ['org', 'O', 'O', 'gpe', 'O'],
  'words': ['Mickey Mouse', 'lives', 'in', 'Santa Maria', '.']},
 'AllenNLP': {'text': 'Mickey Mouse lives in Santa Maria.',
  'tags': ['per', 'O', 'O', 'gpe', 'O'],
  'words': ['Mickey Mouse', 'lives', 'in', 'Santa Maria', '.']},
 'CorTex': {'text': 'Mickey Mouse lives in Santa Maria.',
  'words': ['Mickey Mouse', 'lives', 'in', 'Santa Maria', '.'],
  'tags': ['per', 'O', 'O', 'geo', 'O']}
};*/
     });

};

modelChange = (value,e) => {
    console.log("modelChangevalue",value);
    const items = this.state.modelNameControls;
      const selModel = this.state.selectedModel;
    if(value){
      
      items.map((itm)=>{
        if(itm.value == e.target.value){
          itm.checked = value;
        }
      });
  
     
  
  if (selModel.indexOf(e.target.value) === -1) selModel.push(e.target.value);
  
      this.setState({selectedModel : selModel,modelNameControls:items});
    }else{   
      items.map((itm)=>{
        if(itm.value == e.target.value){
          itm.checked = value;
        }
      });
  
      var index = selModel.indexOf(e.target.value);
      if (index > -1) {
        selModel.splice(index, 1);
      }
      this.setState({selectedModel : selModel,modelNameControls:items});
    }
    
  };  

sentenceChange = (value) => {
  this.setState({sentence: value});
};

exampleSelect = (value) => {
  this.setState({sentence: value});
};

progressTimeout = null;
  uploadProgressTimeout = null;
  handleChange = value => {
    this.setState({value})
  }

  handleTagChange = value => {
   // this.state.value[this.state.value.length-1].tag = value;
   // this.state.value[this.state.value.length-1].color = TAG_COLORS[value]
    this.setState({tag: value})
  }

  handleLoad = (uploadedFile, uploadedData) => {
    const { name, size, type, lastModified } = uploadedFile;
    const d = new Date(lastModified)
    //var d = new Date('Wed May 27 2020 16:19:30 GMT+0530 (India Standard Time)');
    const n = d.toLocaleDateString().split("/");
    const last_modified = `${n[1]}/${n[0]}/${n[2]}`
    console.log("uploadedData",uploadedData);    
       this.setState({ fileName: name, fileSize: size,uploadedData:type === "text/plain" ? uploadedData : '',lastModified:last_modified });
  };

   handleLoadStart = () => {
    this.setState({ progress: 0 });
  };
   fileChange = (files,e) => {
    console.log("files",files);
    this.setState({file:files});
  };

  dataChange = (e) => {
    this.setState({uploadedData:e.target.value,fileName:'',file:null})
  }
    
    render() {
      
    return (
    <div className="container-fluid pl-0 pr-0 ">
    <div className="d-flex flex-row w-100">
    <div className="w-10 p-5">
    <Link to="/offerings/named-entity-recognition"  className="fa fa-arrow-left float-left pl-2 mx-auto"> Back</Link>
      </div>
      <div className="pl-3 pt-4 pb-5 pr-3  w-90">  
        <h2 className="pt-3 pl-3">Document Tagging</h2>
       <div class="card w-100 border-0">
       <div class="card-body pt-2 d-flex flex-row">
       
        
       
   <div className="w-40 d-flex flex-column">
   <div className="d-flex flex-row w-100 doc-icons ">
        <div><span className="d-block text-center">Document 1</span><img src={txt_icon1} id={doc1} onClick={this.analyze} className=""/> </div>
        <div><span className="d-block text-center">Document 2</span><img src={txt_icon2} id={doc2} onClick={this.analyze} className=""/></div>
        <div><span className="d-block text-center">Document 3</span><img src={txt_icon3} id={doc3} onClick={this.analyze} className=""/> </div>
        {/*<div><span className="d-block text-center">Document 4</span> <img src={txt_icon4} id={doc4} onClick={this.analyze} className=""/></div>*/}
                </div>
                <div className="d-flex flex-column mt-3 ">
                <h3>Upload Text Document(.txt file)</h3>
<div className="pl-3 pt-2 pb-1 pr-1 d-flex flex-row  bg-white rounded shadow w-100"><FileUpload
          id="server-upload-file"
          label="Choose file"
          onLoad={this.handleLoad}
          onLoadStart={this.handleLoadStart}
          required
          accept={["text/plain"]}
          name="file"
          allowDuplicates = {true}
          className="file-inputs__upload-form__file-upload ml-0 rounded-pill d-inline mr-4"
          onChange={this.fileChange}
          icon={null}
          
          primary
          iconBefore
        />
        <TextField
          id="server-upload-file-field"
          placeholder="No file chosen"
          value={this.state.fileName}
          className="file-inputs__upload-form__file-field d-inline"
          readOnly
          fullWidth={false}
         
        />
        
        </div>
        {this.state.file !== null && this.state.file.type !== "text/plain" && <div className="text text-danger p-3">The selected file is not a .txt file.</div>}
       
       </div>
<h4 className="mt-3">OR</h4>
        <div className="w-100 pt-3"> 
<label for="text">Enter Text</label>
<textarea id="text" onChange={this.dataChange} className="w-100 form-control" rows={10} resize={false} value={this.state.uploadedData}></textarea>

       </div>
       
      </div>

<div className="w-10 align-self-center text-center">
<button className="btn btn-primary m-2" disabled={this.state.uploadedData.length === 0} onClick={this.analyzeDoc}><i className="fa fa-angle-right pr-1"></i> Tag</button>
</div>
      <div className="w-50 border" style={{"maxHeight":"545px","overflow":"auto"}}>
      {!this.state.loaded ? <MDSpinner singleColor="#03a9f4" className="m-5" size={70} borderSize={5}/> : ""}
      {
         this.state.error.length > 0 ? <span className="text-danger m-2">{this.state.error}</span> : ""
       }
{this.state.result != null ? <div class="card w-100 border-0">
<div class="card-body" id="ner">

<h3>Model Name</h3>
{this.state.modelNameControls.map((item,index)=>(
  <Checkbox
  id={`checkbox${index}`}         
  label={item.label}
  value={item.value}
  checked={item.checked}
  inline={true}
  onChange={this.modelChange}
  className={`${(item.value == `SpaCy` || item.value == `AllenNLP`) ? `d-none` : `d-inline`}`}
/>
))    }
  

{console.log("this.state.selectedModel",this.state.selectedModel)}
<div className="d-flex flex-row">
{this.state.selectedModel.length > 0 ? this.state.selectedModel.map((mName,index)=> (

  mName != "" ? 
 
  <div className={`highlight-container bg-light rounded shadow w-100 p-2 mb-3`}>
  <h4 className="d-block w-100 pl-1 border-bottom">{mName}</h4>
  {this.state.result && this.state.result[mName] && this.state.result[mName].words && this.state.result[mName].words.map((word,index) => (
  
  
      <span className={`${(this.state.result[mName].tags[index] != 'O'? 'highlight' : '')} ${this.state.result[mName].tags[index].toUpperCase()}`}>
        <span className={`mr-2 ${(this.state.result[mName].tags[index] != 'O'? 'highlight-content' : '')}`}>{word}</span>
        <span className={`${(this.state.result[mName].tags[index] != 'O'? 'highlight-label' : '')} ${(this.state.result[mName].tags[index] != 'O'? 'd-block' : 'd-none')}`}><strong>{this.state.result[mName].tags[index].toUpperCase()}</strong></span>
        </span>
        
        ))}</div>
          
       


       
        : ""))
        

        
        : ""}
        </div>
{/*{Object.keys(this.state.result).map((key) => (
  
<span className={`${(this.state.result[key] != 'O'? 'highlight' : '')} ${this.state.result[key].split('-').slice(1)}`}>

<span className={`${(this.state.result[key] != 'O'? 'highlight-content' : '')}`}>{key}</span>
<span className={`${(this.state.result[key] != 'O'? 'highlight-label' : '')} ${(this.state.result[key] != 'O'? 'visible' : 'invisible')}`}><strong>{this.state.result[key]}</strong></span>

</span>

//<span>Key: {key}, Value: {this.state.result[key]}</span>
))
}*/}
{/* <span ng-repeat="(key,value) in vm.answer" ng-class="[{'highlight':value != 'O'},value.split('-').slice(1)]">
   <span ng-class="{'highlight-content':value != 'O'}">{{key}}</span>
<span ng-if="value != 'O'" ng-class="{'highlight-label':value != 'O'}"><strong>{{value}}</strong></span>
</span>*/}

</div>
</div>    : ""} 
        </div>

       </div>
       </div>  
      
      

       

       </div>  
       </div>
       <Footer/>
 </div>   
);
}
}
export default DocumentTagging;