
import React, { Component } from 'react';

import logo from '../../images/logo.JPG';
import kaasLogo from '../../images/kaas_logo.JPG';
import '../../App.css';
import Footer from '../footer/footer.js';
import axios from 'axios';
import MDSpinner from "react-md-spinner";
import {
  Button,
  CardActions,
  FileUpload,
  LinearProgress,
  Snackbar,
  TextField,
  FontIcon,
  Checkbox,
  SelectField,
  SelectionControlGroup,
  FileInput
} from 'react-md';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as apiConstants from '../../service-api-constants.js';


class NamedEntityRecognition extends Component {
  constructor(props) {
    super(props);

    this.state = {
        sentence: "",
        result: null,
        modelNameControls: [],
        selectedModel: [],
        error:'',
        loaded:true  
    }
}

analyze = () =>{
 
 var reqData = {"sentence" : this.state.sentence};
 this.setState({loaded:false,error:'',result: null})
 /* axios({
    method: 'post',
    url: 'http://13.58.187.191:8080/ner',
    config: { headers: {'Content-Type': 'application/json' },
    body: reqData
}
    })
    fetch('http://13.58.187.191:8080/ner', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(reqData)
  })*/
  axios.post(apiConstants.NERSERVICEURL, {"sentence" : this.state.sentence},
  {headers: {'Content-Type': 'application/json' }}
    )  
.then((res) => {
    console.log(res);
    const data = res.data;          
    const mNames = ["KaaS","SpaCy","AllenNLP","Chemical","Disease"]; //Object.keys(data);
    const radioModelNames = mNames.map((x,index)=> { 
     // const check = index == 0 ? true : false;
     const check = false;
      return { 
        label: x, 
        value: x,
        checked: check
      }; 
    });
 
    this.setState({modelNameControls : radioModelNames,result:data,loaded:true,selectedModel:[]/*selectedModel:[Object.keys(data)[1]]*/}); 
    
 

}).catch((error) => {
    console.log("error",error);
    this.setState({error: error.toString(),loaded:true});
});
 /* const data = {'SpaCy': {'text': 'Mickey Mouse lives in Santa Maria.',
  'tags': ['org', 'O', 'O', 'gpe', 'O'],
  'words': ['Mickey Mouse', 'lives', 'in', 'Santa Maria', '.']},
 'AllenNLP': {'text': 'Mickey Mouse lives in Santa Maria.',
  'tags': ['per', 'O', 'O', 'gpe', 'O'],
  'words': ['Mickey Mouse', 'lives', 'in', 'Santa Maria', '.']},
 'CorTex': {'text': 'Mickey Mouse lives in Santa Maria.',
  'words': ['Mickey Mouse', 'lives', 'in', 'Santa Maria', '.'],
  'tags': ['per', 'O', 'O', 'geo', 'O']}
};*/
  
 
};

modelChange = (value,e) => {
  console.log("modelChangevalue",value);

if(e.target.value === "Disease" || e.target.value === "Chemical"){

  axios.post(apiConstants.ENHANCEDNERURL, {"sentence" : this.state.sentence},
  {headers: {'Content-Type': 'application/json' }}
    )  
.then((response) => {
  console.log(response);
  const data1 = response.data; 
if(response.data.errno){
this.setState({error: response.data.errno});
}else{         
  const mNames1 = Object.keys(data1);
  const radioModelNames = this.state.modelNameControls;
  const data = this.state.result; 
  const radioModelNames1 = mNames1.map((x,index)=> { 
    //const check = index == 0 ? true : false;
    return { 
      label: x, 
      value: x,
      checked: false
    }; 
  });
  radioModelNames.push(radioModelNames1[0]);
  radioModelNames.push(radioModelNames1[1]);
  data["Chemical"] = data1["Chemical"];
  data["Disease"] = data1["Disease"];
  console.log("radioModelNames",radioModelNames);
  console.log("Object.keys(data)[1]",Object.keys(data)[1]);
  //this.modelChange(radioModelNames[0].value);
  this.setState({modelNameControls : radioModelNames,result:data,loaded:true/*selectedModel:[Object.keys(data)[1]]*/}); 
  const items = radioModelNames;
  const selModel = this.state.selectedModel;
if(value){
  
  items.map((itm)=>{
    if(itm.value == e.target.value){
      itm.checked = value;
    }
  });

 

if (selModel.indexOf(e.target.value) === -1) selModel.push(e.target.value);

  this.setState({selectedModel : selModel,modelNameControls:items});
}else{   
  items.map((itm)=>{
    if(itm.value == e.target.value){
      itm.checked = value;
    }
  });

  var index = selModel.indexOf(e.target.value);
  if (index > -1) {
    selModel.splice(index, 1);
  }
  this.setState({selectedModel : selModel,modelNameControls:items});
}
}
}).catch((error) => {
      console.log("error",error);
      this.setState({error: error.toString(),loaded:true});
  });

}else{
  const items = this.state.modelNameControls;
  const selModel = this.state.selectedModel;
if(value){
  
  items.map((itm)=>{
    if(itm.value == e.target.value){
      itm.checked = value;
    }
  });

 

if (selModel.indexOf(e.target.value) === -1) selModel.push(e.target.value);

  this.setState({selectedModel : selModel,modelNameControls:items});
}else{   
  items.map((itm)=>{
    if(itm.value == e.target.value){
      itm.checked = value;
    }
  });

  var index = selModel.indexOf(e.target.value);
  if (index > -1) {
    selModel.splice(index, 1);
  }
  this.setState({selectedModel : selModel,modelNameControls:items});
}
}

 
  
};  
sentenceChange = (value) => {
  this.setState({sentence: value});
};

exampleSelect = (value) => {
  this.setState({result: null,sentence: value,error:''});
};
    
    render() {
      
    return (
    <div className="container-fluid pl-0 pr-0 ">
    <div className="d-flex flex-row w-100">
    <div className="w-10 p-5">
    <Link to="/offerings/named-entity-recognition"  className="fa fa-arrow-left float-left pl-2 mx-auto"> Back</Link>
      </div>
      <div className="pl-3 pt-4 pb-5 pr-3  w-90">  
        <h2 className="pt-3 pl-3">Named Entity Recognition</h2>
       <div class="card w-100 border-0">
       <div class="card-body pt-2">
       <div className="d-flex flex-row">
       <span className="d-inline-block align-self-end" style={{"whiteSpace":"nowrap","lineHeight":"55px","fontSize":"14px"}}>Enter text or </span>
       <SelectField
      id="example"
      placeholder="Choose an example..."
      className="md-cell d-block w-100"
      
      menuItems={["allennlp is an nlp library developed at the allen institute for artificial intelligence and facebook ai research in seattle, usa.","she worked at stephen & co when everything crashed.","his father worked for state bank of india since 1985.",
"Long-term exposure to certain pesticides or industrial chemicals like benzene is considered a risk for leukemia.", 
        "The beta-amyloid protein involved in Alzheimer's comes in several different molecular forms that collect between neurons.", 
        "tiotropium bromide is indicated for the long-term once-daily maintenance treatment associated with chronic obstructive pulmonary disease (COPD)"]}    
      
      onChange={this.exampleSelect} 
      value={this.state.sentence}
    />
      </div>
        <div className="d-flex flex-row"><TextField
      id="floating-center-title"
     
      lineDirection="center"
      placeholder="Type a sentence"
      className="text-field mt-3"
      value={this.state.sentence}
      rows={1}
      onChange={this.sentenceChange}
    />
    <Button className="mt-4 ml-3 align-self-end mb-1 rounded-pill" disabled={!this.state.sentence.length>0} onClick={this.analyze} raised primary>Analyze</Button>
     </div>
       

       </div>
       </div>  
       {!this.state.loaded ? <MDSpinner singleColor="#03a9f4" className="m-5" size={70} borderSize={5}/> : ""}
      {this.state.result != null ? <div class="card w-100 border-0">
       <div class="card-body" id="ner">
      <h3>Model Names</h3>
       {this.state.modelNameControls.map((item,index)=>(
         <Checkbox
         id={`checkbox${index}`}         
         label={item.label}
         value={item.value}
         checked={item.checked}
         inline={true}
         onChange={this.modelChange}
         
       />
       ))    }
   
            {/*    <SelectionControlGroup
                  id="selection-control-group-radios"
                  name="radio-example"
                  type="checkbox"
                  label="Model Names"
                  className="pl-1"
                  inline={true}
                  defaultValue={this.state.modelNameControls[0].value}
                  controls={this.state.modelNameControls}
                  onChange = {this.modelChange}
                          
            />*/}
         
      
       {console.log("this.state.selectedModel",this.state.selectedModel)}
       {this.state.selectedModel.length > 0 ? this.state.selectedModel.map((mName)=> (
       
         mName != "" ? 
         <div className="highlight-container bg-light rounded shadow w-100 p-2 mb-3">
         <h4 className="d-block w-100 pl-1 border-bottom">{mName}</h4>
         {this.state.result !== null && this.state.result[mName] && this.state.result[mName].words && this.state.result[mName].words.map((word,index) => (
         
         
             <span className={`${(this.state.result[mName].tags[index] != 'O'? 'highlight' : '')} ${this.state.result[mName].tags[index].toUpperCase()}`}>
               <span className={`mr-2 ${(this.state.result[mName].tags[index] != 'O'? 'highlight-content' : '')}`}>{word}</span>
               <span className={`${(this.state.result[mName].tags[index] != 'O'? 'highlight-label' : '')} ${(this.state.result[mName].tags[index] != 'O'? 'd-block' : 'd-none')}`}><strong>{this.state.result[mName].tags[index].toUpperCase()}</strong></span>
               </span>
               
               ))}</div> : "")) : ""}
       {/*{Object.keys(this.state.result).map((key) => (
         
 <span className={`${(this.state.result[key] != 'O'? 'highlight' : '')} ${this.state.result[key].split('-').slice(1)}`}>
     
     <span className={`${(this.state.result[key] != 'O'? 'highlight-content' : '')}`}>{key}</span>
      <span className={`${(this.state.result[key] != 'O'? 'highlight-label' : '')} ${(this.state.result[key] != 'O'? 'visible' : 'invisible')}`}><strong>{this.state.result[key]}</strong></span>
      
       </span>
       
       //<span>Key: {key}, Value: {this.state.result[key]}</span>
      ))
}*/}
      {/* <span ng-repeat="(key,value) in vm.answer" ng-class="[{'highlight':value != 'O'},value.split('-').slice(1)]">
          <span ng-class="{'highlight-content':value != 'O'}">{{key}}</span>
		  <span ng-if="value != 'O'" ng-class="{'highlight-label':value != 'O'}"><strong>{{value}}</strong></span>
</span>*/}

       </div>
       </div>    : ""} 

       {
         this.state.error.length > 0 ? <span className="text-danger pl-4">{this.state.error}</span> : ""
       }

       </div>  
       </div>
       <Footer/>
 </div>   
);
}
}
export default NamedEntityRecognition;