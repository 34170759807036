
import React, { Component } from 'react';
import logo from '../../images/logo.JPG';
import kaasLogo from '../../images/kaas_logo.JPG';
import Mayank from '../../images/mayank.jpg';
import Mukul from '../../images/mukul.jpg';
import Deepak from '../../images/deepak.jpg';
import Anupam from '../../images/anupam.jpg';
import Archana from '../../images/archana.jpg';
import Tushar from '../../images/tushar.jpg';
import Rishi from '../../images/rishi.jpg';
import Vaibhav from '../../images/vaibhav.jpg';
import Team from '../../images/team.jpg';
import Sudhan from '../../images/Sudharsan-Rangarajan.JPG';

import '../../App.css';
import Footer from '../footer/footer.js';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


class MeetOurTeam extends Component {
    
    render() {
    
    return (
    <div>
        <div className="container-fluid pl-0 pr-0 d-flex flex-row ">
           <div className="text-dark pt-4 pl-4 pb-2 w-40 align-self-center">
           <h1 className="d-block pl-0 text-dark mot-header">Meet Our Team</h1>
           <p className="pl-0  w-75">Our team is obsessed to make your AI journey smooth and productive.</p>

<p className="pl-0  w-75">When your mission is to leverage AI to make smarter business decisions, you need the best people driving your vision forward. You need people who understand AI, the complexities that come with it and the challenges to use it in the right business context.</p>

<p className="pl-0 w-75">You need KaaS. Get to know your team below.</p>
           
           </div>
          <div className="w-60 bg-white pl-0 banner pb-0 float-right">
          <img src="/images/aiconics.jpg"/>
            {/*<div className="d-flex flex-row flex-fill pt-3">
           <div className="pl-3 pr-3"><img src={Deepak} className="w-75"/></div>
           <div className="pl-3 pr-3"><img src={Mayank} className="w-75"/></div>
           <div className="pl-3 pr-3"><img src={Mukul} className="w-75"/></div>

               </div>
               <div className="d-flex flex-row pt-3">
           
           <div className="w-25">&nbsp;</div>
           <div><img src={Anupam} className="w-75 pl-5 ml-4"/></div>
           <div><img src={Archana} className="w-75 pl-3 ml-3"/></div>

               </div>*/}
              
           </div>
        </div>
        <div className="container-fluid mt-0 pl-0 pr-0 bg-dark">
      <table className="team-members mt-0 mx-auto" >
          <tr>
          <td className="text-center"><img src={Anupam} className=""/></td>
              <td className="text-center"><img src={Archana} className=""/></td>
              <td className="text-center"><img src={Mayank} className=""/></td>
              <td className="text-center"><img src={Mukul} className=""/></td>
              
              </tr>
              <tr>
                  <td className="text-center text-white">Anupam Tiwari<br/><a href="mailto:anupam.tiwari@publicissapient.com" target="_top">anupam.tiwari@publicissapient.com</a></td>
                  <td className="text-center text-white">Archana P<br/><a href="mailto:archana.p@publicissapient.com" target="_top">archana.p@publicissapient.com</a></td>
                  <td className="text-center text-white">Mayank Saini<br/><a href="mailto:mayank.saini@publicissapient.com" target="_top">mayank.saini@publicissapient.com</a></td>
                  <td className="text-center text-white">Mukul Malik<br/><a href="mailto:mukul.malik@publicissapient.com" target="_top">mukul.malik@publicissapient.com</a></td>
                 
              </tr>
              <tr>
              <td className="text-center"><img src={Rishi} className=""/></td>
              <td className="text-center"><img src={Tushar} className=""/></td>             
              <td className="text-center"><img src={Sudhan} className=""/></td>
              <td className="text-center"><img src={Vaibhav} className=""/></td>
              </tr>
              <tr>
              <td className="text-center text-white">Rishi Bajargan<br/><a href="mailto:rishi.bajargan@publicissapient.com" target="_top">rishi.bajargan@publicissapient.com</a></td>
                  <td className="text-center text-white">Tushar Mehtani<br/><a href="mailto:tushar.mehtani@publicissapient.com" target="_top">tushar.mehtani@publicissapient.com</a></td>
                  <td className="text-center text-white">Sudharsan Rangarajan<br/><a href="mailto:sudharsan.rangarajan@publicissapient.com" target="_top">sudharsan.rangarajan@publicissapient.com</a></td>
                  <td className="text-center text-white">Vaibhav Gupta<br/><a href="mailto:vaibhav.gupta5@publicissapient.com" target="_top">vaibhav.gupta5@publicissapient.com</a></td>
              </tr>

          </table>
    </div>
    <div className="container-fluid pl-0 pr-0">
    <Footer/>
    </div>
 </div>   
);
}
}
export default MeetOurTeam;