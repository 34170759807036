
import React, { Component } from 'react';
import '../../App.css';
import Footer from '../footer/footer.js';
import MC from '../../images/services/mc.jpg';
import { TabsContainer, Tabs, Tab } from 'react-md';
import axios from 'axios';
import MDSpinner from "react-md-spinner";
import TextHighlight from 'react-text-highlight';
import {
  Button,
  CardActions,
  FileUpload,
  LinearProgress,
  Snackbar,
  TextField,
  FontIcon,
  Checkbox,
  SelectField,
  SelectionControlGroup,
  FileInput
} from 'react-md';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as apiConstants from '../../service-api-constants.js';
//import SelectEditable from '../select-editable/select-editable.js'

class TextSummarization extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            minLength:"100",
            maxLength:"200",
            pruneText:"false",
            minData:["100","200","300"],
            maxData:["100","200","300"],
            pruneData:["true","false"],
            text:'',
            url:'',
           
            result: null,
            error:'',
            loaded:true,
           
        }
    }
    analyze = () => {
        this.setState({loaded:false,result:null});
        axios.post(apiConstants.TEXT_SUMMARIZATION, {"payload": [{"text": this.state.text, "textUrl": this.state.url}]},
    {headers: {'Content-Type': 'application/json' }}
      ) 
            .then((response) => {
                console.log("response", response);
                this.setState({loaded:true,result:response.data.response[0],text:response.data.response[0].text});
            })
            .catch((error) => {              
                this.setState({loaded:true});

            });
    }

    titleChange = (e,value=undefined) => {
        if(value !== undefined && value !== null){
          this.setState({selectedTitle:value}); 
        }else{
          this.setState({selectedTitle:e.target.value}); 
        }
        
     //   this.textInput.current.focus();     
       };
  
     

    minChange = (value) => {
        this.setState({minLength:value})
    }

    maxChange = (value) => {
        this.setState({maxLength:value})
    }

    pruneChange = (value) => {
        this.setState({pruneText:value})
    }

    isURL = (str) => {
        //var pattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str); 
        return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str); 
      }

      urlChange = (e) => {
          this.setState({url:e.target.value,text:''});
      }

      textChange = (e) => {
        this.setState({text:e.target.value});
    }
    
    render() {
    const {result,context,question} = this.state;
       return (

        <div className="container-fluid pl-0 pr-0 ">
        <div className="d-flex flex-row w-100">
        <div className="w-10 p-5">
        <Link to="/offerings/text-summarization"  className="fa fa-arrow-left float-left pl-2 mx-auto"> Back</Link>
          </div>
          <div className="pl-3 pt-4 pb-5 pr-3  w-100">  
            <h2 className="pt-3 pl-3">Text Summarization</h2>
            <div className="d-flex flex-row w-100">
            <div className="w-40">
           <div class="card  border-0">
           <div class="card-body pt-0 text-center">
           <h4 className="font-weight-bold">Enter URL</h4>
           <textarea className="p-2 mb-3 rounded form-control" onChange={this.urlChange} name="url" id="url" style={{"width":"100%","height":"40px","resize": "none"}} maxlength="1000" placeholder="URL must start with 'http://' or 'https://'" size="500" value={this.state.url}></textarea>
           <h4 className="font-weight-bold">Or</h4>
           <h4 className="font-weight-bold">Paste text</h4>
           <textarea className="p-2 rounded form-control" onChange={this.textChange} name="text" id="text" style={{"width":"100%","height":"300px","resize": "none"}} maxlength="50000" size="5000" value={this.state.text}></textarea>
           
           
    
           </div>
           </div>  
</div>
<div className="w-10 pl-3 pr-3 pt-0 pb-3 mt-0 text-center align-self-center">
{/*<h4 className="font-weight-bold">Min Length</h4>
<SelectEditable titles={this.state.minData} selectedTitle={this.state.minLength} titleChange={this.minChange} />
<h4 className="font-weight-bold">Max Length</h4>
 <SelectEditable titles={this.state.maxData} selectedTitle={this.state.maxLength} titleChange={this.maxChange} />
 <h4 className="font-weight-bold">Prune Text</h4>
  <SelectEditable titles={this.state.pruneData} selectedTitle={this.state.pruneText} titleChange={this.pruneChange} />*/}
  <Button className="mt-4 ml-0 align-self-end mb-1 rounded-pill" onClick={this.analyze} disabled={(this.state.url.length>0 && !this.isURL(this.state.url)) || (this.state.url.length === 0 && this.state.text.length === 0)} raised primary>Run <i className="fa fa-angle-right"></i></Button>
  {/*parseInt(this.state.minLength) > parseInt(this.state.maxLength) ? <span className="text text-danger d-block">Error : min length is greater than max length</span> : ""*/}
  {this.state.url.length>0 && !this.isURL(this.state.url) ? <span className="text text-danger d-block">Error : url is invalid</span> : ""}
{/*this.state.text.length>0 && this.state.text.length > parseInt(this.state.maxLength) ? <span className="text text-danger d-block">Error : Length of input text is greater than Max Length of summary. Please select a lower value</span> : ""*/}
</div>
<div className="w-50 pl-3">
           {!this.state.loaded ? <MDSpinner singleColor="#03a9f4" className="m-5" size={40} borderSize={5}/> : ""}
           {result !== null && <div class="card border-0">
       <div class="card-body pt-0 text-center">
       <div class="card-title "><h4 className="font-weight-bold">Generated Summary</h4></div>

      <textarea className="p-2 rounded form-control" name="text" id="text" style={{"width":"100%","height":"440px","resize": "none"}} maxlength="50000" size="5000" value={this.state.result.summary_text}></textarea>
       </div>
       </div> } 
       </div>
       </div>
           {
             this.state.error.length > 0 ? <span className="text-danger pl-4">{this.state.error}</span> : ""
           }
    
           </div>  
           </div>
           <Footer/>    

        </div>
);
}
}
export default TextSummarization;