
import React, { Component } from 'react';
import '../../App.css';
import Footer from '../footer/footer.js';



class Swaggerui extends Component {
    
    render() {
    
    return (
    <div>
        <div className="container-fluid pl-0 pr-0">
           <div className="bg-dark text-white pt-4 pl-4 pb-2">
           <h1 className="d-block pl-3 text-white offerings-header">KaaS Cognitive Services</h1></div>
        </div>
    <div className="container-fluid mt-3  pb-3 bg-light d-flex flex-row">
    <iframe src="http://api.autokaas.com/" style={{"width":"100%","height":"100vh"}} />
    </div>
    <div className="">
 <Footer/>
          </div>  
 </div>   
);
}
}
export default Swaggerui;