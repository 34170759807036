

import React, { Component } from 'react';
import '../../App.css';
import Footer from '../footer/footer.js';
import MC from '../../images/services/mc.jpg';
import { TabsContainer, Tabs, Tab } from 'react-md';
import axios from 'axios';
import MDSpinner from "react-md-spinner";
import {
  Button,
  CardActions,
  FileUpload,
  LinearProgress,
  Snackbar,
  TextField,
  FontIcon,
  Checkbox,
  SelectField,
  SelectionControlGroup,
  FileInput
} from 'react-md';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as apiConstants from '../../service-api-constants.js';


class SemanticRanking extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            question: '',
            result: null,
            error:'',
            loaded:true,
            menuItems: ["What do robots that resemble humans attempt to do?",
            "How many passing touchdowns were there in the game?",
            "Who threw the longest touchdown pass of the game?"
          ],
          answer:["answer1"],
          answer1:"",
          answer2:"",
          answer3:"",
          answer4:"",
          answer5:"",
          knowledgeBaseNames:[],
          selectedKb:'',
        }
    }

    componentDidMount(){     
     this.getIndex();
     
    }

    getIndex = (update=false) => {
      this.setState({loaded:false});
      axios({
        method: 'get',
        url: apiConstants.GETKNOWLEDGEBASES,
        config: { headers: {'Content-Type': 'application/json' },
    }
        })
        .then((response) => {
            //handle success
            console.log(response);
            const data = response.data;
            if(data.errno){
              this.setState({error:data.errno.toString(),loaded:true});
            } else{
              if(Array.isArray(data) && data.length>0){
              //data.map((item,index)=>{           
              //  item.checked=false;                
              // });
              console.log("data",data);
              this.setState({knowledgeBaseNames:data,loaded:true});  
              
            }
            } 
                   
        })
        .catch((error) => {
            console.log("error",error);
            this.setState({error:error.toString(),loaded:true});
        });
    };

    questionChange = (value) => {
      this.setState({question: value});
    };
    
    kbSelect = (value) => {
      this.setState({result: null,selectedKb: value,error:''});
    };     
    
    analyze = () => {
      if(this.state.question.length > 0 && this.state.selectedKb.length > 0){
        this.setState({loaded:false,result:null});
        axios.post(apiConstants.SEARCH_RESULTS, {question: this.state.question, esKnowledgebase:this.state.selectedKb},
          {headers: {'Content-Type': 'application/json' }}
            ).then((response) => {
                console.log("response", response);
                this.setState({loaded:true,result:response.data});
            })
            .catch((error) => {             
                console.log("error", error);
                this.setState({loaded:true});

            });
          }
    }

    answerChange = (value,event) => {
      this.setState({[event.target.name]: value});
    };

    addAnswer = () => {
      const answer = this.state.answer;
      let len = answer.length;
      answer.push(`answer${len-1}`);
      this.setState({answer:answer});
    };
    
    render() {
    const {result,question} = this.state;
    return (

        <div className="container-fluid pl-0 pr-0 ">
        <div className="d-flex flex-row w-100">
        <div className="w-10 p-5">
        <Link to="/offerings/semantic-ranking"  className="fa fa-arrow-left float-left pl-2 mx-auto"> Back</Link>
          </div>
          <div className="pl-3 pt-4 pb-5 pr-3  w-90">  
            <h2 className="pt-3 pl-3">Semantic Ranking</h2>
            <div class="card w-100 border-0">
       <div class="card-body pt-2">
       <div className="d-flex flex-row">
       
       <SelectField
      id="example"
      placeholder="Select knowledge base"
      className="d-block w-75"
      
      menuItems={this.state.knowledgeBaseNames}    
      itemLabel="name"
      itemValue="name"
      onChange={this.kbSelect} 
      value={this.state.selectedKb}
    />
      </div>
        <div className="d-flex flex-row">
                  <div className="w-65">

                    <TextField
                      id="floating-center-title"
                      label="Search Query"
                      lineDirection="center"
                      placeholder="Enter what do you want to know about"
                      className="text-field mt-3 w-100"
                      value={this.state.question}
                      rows={1}
                      onChange={this.questionChange}
                    />
                  </div>
    <div className="w-10  pl-3 align-self-center">
      <Button className="mt-5 mb-1 rounded-pill" onClick={this.analyze} disabled={this.state.question.length == 0} raised primary>Search</Button>
      </div>
     </div>
       

       </div>
       </div>   
           {!this.state.loaded ? <MDSpinner singleColor="#03a9f4" className="m-5" size={70} borderSize={5}/> : ""}
           {result && result.qna_response && <div className="d-flex flex-column w-100 mt-5 pl-2">
             {result.qna_response[0] && result.qna_response[0].full_content_unranked && result.qna_response[0].full_content ? <div className="d-flex flex-row ml-2 pl-1">
             <div className="w-49 p-3 m-2 border rounded heading text-center"><strong>Syntactic matching results</strong></div> 
             <div className="w-50 p-3 m-2 border rounded heading text-center"><strong>Semantic matching results</strong></div>
    </div> : <div className="p-3">{result.qna_response[0].curiosum_top_answer && <p className="text-danger">{result.qna_response[0].curiosum_top_answer}</p>}</div>}
             {result.qna_response.map((res,index)=>(
 <div className="d-flex flex-row">
 {res.full_content_unranked && res.full_content_unranked.length > 0 && <div className="d-flex flex-row w-50 m-2 ranking-scroll"><div className="pr-2 pt-3"><strong>{index+1}</strong></div><div className=" p-3 border rounded"><p>{res.full_content_unranked}</p></div></div>}
 {res.full_content && res.full_content.length > 0 && <div className="w-50 p-3 m-2 border rounded ranking-scroll"><p>{res.full_content}</p></div>}
 </div>
             ))}
            
       </div> } 
    
           {
             this.state.error.length > 0 ? <span className="text-danger pl-4">{this.state.error}</span> : ""
           }
    
           </div>  
           </div>
           <Footer/>
     
        </div>
);
}
}

export default SemanticRanking;