import React, { Component } from 'react';

import './App.css';


import Header from './components/header/header.js';
import HomePage from './components/home-page/home-page.js';
import Offerings from './components/offerings/offerings.js';
import Aboutus from './components/about-us/about-us.js';
import QuestionUnderstanding from './components/question-understanding/qu.js';
import MachineComprehension from './components/machine-comprehension/mc.js';
import NamedEntityRecognition from './components/ner/ner.js';
import DocumentTagging from './components/document-tagging/document-tagging.js';
import ActiveLearning from './components/active-learning/active-learning.js';
import CoreferenceResolution from './components/coreference-resolution/coreference-resolution.js';
import RelationExtraction from './components/relation-extraction/relation-extraction.js';
import LearntoRank from './components/learn-to-rank/learn-to-rank.js';
import SemanticRanking from './components/semantic-ranking/semantic-ranking.js';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import MC from './images/services/mc.jpg';
//import QU from './images/services/qu.jpg';
import ServicesDemo from './components/services-demo.js';
import MeetOurTeam from './components/meet-our-team/meetourteam.js';
import PhraseCompletion from './components/phrase-completion/phrasecompletion.js';
import Swaggerui from './components/swagger-ui/swaggerui.js';
import Documents from './components/documents/documents.js';
import Blogs from './components/blogs/blogs.js';
import Login from './components/login/login.js';
import TextSummarization from './components/text-summarization/text-summarization.js';
import axios from 'axios';
import * as apiConstants from './service-api-constants.js';
import WordEmbedding from './components/word-embedding/word-embedding.js';
import AutomatedDocumentTagging from './components/auto-doc-tagging/auto-doc-tagging.js';

class App extends Component { 
constructor(props) {
                super(props);
            
                this.state = {
            loggedIn:false,
            showError:false,
            password:'',
            apiKey:''
                }
        }
       
      
              logIn = (username,password) => {
               //this.setState({userName:username,loaded:false,   loggedIn:true,adminLoggedIn:true, showError:false,loaded:true});
                if(username.length > 0 && password.length > 0){
                    this.setState({loggedIn:false,showError:false});
              axios.post(apiConstants.VERIFY_USER_LOGIN,
                   {"userName": username,"password": password,"channel":"autokaas-ui"},
                  {
                    headers: {
                      'Content-Type': "application/json"
                    }
                  }
                   ).then((response) => {
                       //handle success
                       console.log(response.data);
                        if(response.data.login){
                        this.setState({loggedIn:true,loaded:false,apiKey:response.data.apiKey});                           
                       }else{
                        this.setState({loggedIn:false,showError:true,apiKey:''});
                       }
                            
                   })
                   .catch((error) => {
                       console.log("error",error);
                       
                       this.setState({loggedIn:false,showError:true,apiKey:''});
                  });
                }else{
                    this.setState({loggedIn:false,showError:true});
                }
               
               
             }

        /*logIn = (username,password) => {
                if(username === "admin" && password === this.state.password){ 
                  
                  this.setState({loggedIn:true,loaded:false});
                }else{
                  
                    this.setState({loggedIn:false,showError:true});
                  
                }
              }*/
  render() {
    return (
 <div>
        {this.state.loggedIn  ? "" : <Login logIn={this.logIn} showError={this.state.showError}/>}
        {this.state.loggedIn && <Router>
      
      <div>
     <Header apiKey={this.state.apiKey}/>   
     <Route exact path="/" component={HomePage} />
     <Route path="/offerings" exact component={Offerings} />
     <Route path="/about-us" exact component={Aboutus} />
<Route path="/meetOurTeam" exact component={MeetOurTeam} />
{/* <Route path="/swaggerui" exact component={Swaggerui} /> */}
     <Route path="/documents" exact component={Documents} />
     <Route path="/blogs" exact component={Blogs} />
	 <Route path="/offerings/automated-document-tagging" exact render={ ()  => <ServicesDemo 
             details={{"title":"Automated Document Tagging","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=tagExtractor","compLink":"/offerings/automated-document-tagging/demo","factSheetUrl":"../factsheets/auto_tagger.pdf","image":"ADT","content":"Automated Document tagging  is the automated process of extracting the most relevant words and expressions from text document. It helps to recognize the main topics which are being discussed and help to improve search accuracy.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"KaaS' Automatic Document Tagger service uses BERT as the vector encoder. Using BERT ensures that each word is disambiguated by default."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"Additionally, multiple linguistic rules are used to extract lexical features."},
             {"featureTitle":"Concepts Extraction","featureDesc":"Automatic Document Tagging has several applications in business intelligence, search engine optimisation, customer feedback analysis etc."}
            ]
          }}
             
           /> } />
      <Route path="/offerings/automated-document-tagging/demo" exact component={AutomatedDocumentTagging} />
<Route path="/offerings/text-summarization" exact render={ ()  => <ServicesDemo 
             details={{"title":"Text Summarization","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=summary","compLink":"/offerings/text-summarization/demo","factSheetUrl":"../factsheets/summarization.pdf","image":"TS","content":"KaaS' Summarizer is capable of generating human-like summary of a given document. Instead of extracting a few lines from within the text, KaaS' Summarizer is able to write a cohesive and coherent summary. The length of the summary can be adjusted by providing a range of words.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"KaaS' Summarizer is capable of generating human-like summary of a given document."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"The length of the summary can be adjusted by providing a range of words."},
             {"featureTitle":"Concepts Extraction","featureDesc":"KaaS' Summarizer, combined with KaaS� Machine Comprehension is capable of merging the answers gathered from different parts of a text or from multiple texts."}
            ]
          }}
             
           /> } />
      <Route path="/offerings/text-summarization/demo" exact component={TextSummarization} />
     <Route path="/offerings/phrase-completion" exact render={ ()  => <ServicesDemo 
             details={{"title":"Phrase Completion","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=phrase_completion","compLink":"/offerings/phrase-completion/demo","factSheetUrl":"../factsheets/phrase_completion.pdf","image":"PC","content":"KaaS' Phrase Completion service suggests the user the most commonly used phrases related to the words they are typing. This service is compatible with all the other services offered by KaaS platform.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"Convert a single HTML, PDF, or Microsoft Word document into HTML, plain text, or a set of JSON-formatted Answer units that can be used with other Cognitive services."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"This is specifically useful to build Question Answering Systems where it is important to surface smaller, specific, logical sections of a document as an answer and not the complete document."},
             {"featureTitle":"Concepts Extraction","featureDesc":"The service not only finds out what topics are mentioned in a piece of text, but also semantic types and URIs, which would allow you to dive deeper into Linked Data to bring in additional, relevant content. It performs very accurate Word Sense Disambiguation."}
            ]
          }}

           /> } />
      <Route path="/offerings/phrase-completion/demo" exact component={PhraseCompletion} />
     <Route path="/offerings/answer-type" exact render={ ()  => <ServicesDemo
             details={{"title":"Answer Type","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=answertype","factSheetUrl":"../factsheets/answer_type.pdf","compLink":"/offerings/answer-type/demo","image":"QU","content":"KaaS' Answer Type service provides the ability to comprehend the types type/nature of information that could answer a question. This can be leveraged to detect which type of Q&A system would be best-suited to answer the question.",
             "features":[{"featureTitle":"Feature 1","featureDesc":"One of its kind service, with proprietary dataset used to train the service"},
             {"featureTitle":"Feature 2","featureDesc":"Can be combined with Machine Comprehension and NER to validate questions"},
             {"featureTitle":"Feature 3","featureDesc":"Can be used to speed-up Question Suggestion"}
            ]
          }}

           /> } />
      <Route path="/offerings/answer-type/demo" exact component={QuestionUnderstanding} />
      <Route path="/offerings/machine-comprehension" exact render={ ()  => <ServicesDemo
                 details={{"title":"Machine Comprehension","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=bidaf","factSheetUrl":"../factsheets/machine_comprehension.pdf","compLink":"/offerings/machine-comprehension/demo","image":"MC","content":"Machine Comprehension is used for retrieval of precise answer to a question from a text. The span of the answer could be anything from a word to list of sentences. It can also be used for questions assisted summarisation.",
                 "features":[{"featureTitle":"Feature 1","featureDesc":"Retrives hierarchy or answer from the paragragh to the exact phrase"},
                 {"featureTitle":"Feature 2","featureDesc":"KaaS' Machine Comprehension achieved F-1 score of 88.49 on dev set of SQuAD 1.1 dataset"},
                 {"featureTitle":"Feature 3","featureDesc":"Uses heavily fine-tuned BERT variants, in ensemble"}
                ]
                }}

     />} />
     <Route path="/offerings/machine-comprehension/demo" exact component={MachineComprehension} />
 <Route path="/offerings/word-embedding/demo" exact component={WordEmbedding} />
     <Route path="/offerings/word-embedding" exact render={ ()  => <ServicesDemo
             details={{"title":"Word Embedding","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=similarPhrase","factSheetUrl":"../factsheets/word_embedding.pdf","compLink":"/offerings/word-embedding/demo","image":"WE","content":"KaaS' Word Embedding service converts text into vectors (phrase vectors). This enables it to perform extended functions like find similar words to a set of words, find semantic similarity between two words etc.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"It uses Phrase2vec instead of word2vec thus creating vector representations for phrases as well along with words."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"Deploys model trained on a huge and varied text corpus namely common crawl and Wikipedia."},
             {"featureTitle":"Concepts Extraction","featureDesc":"Can be used to break sentences into meaningful spans/phrases and finding phrases related to particular word."}
            ]
          }}

           /> } />
     <Route path="/offerings/named-entity-recognition" exact render={ ()  => <ServicesDemo
             details={{"title":"Named Entity Recognition","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=ner","compLink":"/offerings/named-entity-recognition/demo","factSheetUrl":"../factsheets/ner.pdf","image":"NER","content":"This Service identifies named entities (people, locations, organizations, and miscellaneous) in the input text.",
             "features":[{"featureTitle":"Feature 1","featureDesc":"Requires minimal data to train upon custom classes."},
                 {"featureTitle":"Feature 2","featureDesc":"KaaS' Named Entity Recognition achieved F-1 score of 92.8 on CoNLL 2003 dataset"},
                 {"featureTitle":"Feature 3","featureDesc":"Array of variants available, each optimised for different hardware specifications"}
                ]
            }}

     />} />
     <Route path="/offerings/named-entity-recognition/demo" exact component={NamedEntityRecognition} />

    {/* <Route path="/offerings/document-tagging" exact render={ ()  => <ServicesDemo
             details={{"title":"Document Tagging","compLink":"/offerings/document-tagging/demo","factSheetUrl":"../factsheets/ner.pdf","image":"DT","content":"Document Tagging uses Named Entity Recognition is the task of identifying the text of special meaning and classifying into some predetermined categories like person, organisation, location etc but also provides extended capabilities of identifying custom categories like disease, chemical, enzyme, broker, stock value etc.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"Convert a single HTML, PDF, or Microsoft Word document into HTML, plain text, or a set of JSON-formatted Answer units that can be used with other Cognitive services."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"This is specifically useful to build Question Answering Systems where it is important to surface smaller, specific, logical sections of a document as an answer and not the complete document."},
             {"featureTitle":"Concepts Extraction","featureDesc":"The service not only finds out what topics are mentioned in a piece of text, but also semantic types and URIs, which would allow you to dive deeper into Linked Data to bring in additional, relevant content. It performs very accurate Word Sense Disambiguation."}
            ]
            }}

     />} />
        <Route path="/offerings/document-tagging/demo" exact component={DocumentTagging} />*/}

        <Route path="/offerings/section-extraction" exact render={ ()  => <ServicesDemo
             details={{"title":"Section Extraction","compLink":"/offerings/section-extraction/demo","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=extract","factSheetUrl":"../factsheets/section_extraction.pdf","image":"SE","content":"Section Extraction extracts text content from various file formats and sources.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"Multiple file formats supported viz. PDF, doc/docx, txt, csv, ppt, images, scanned PDFs, html, webpages, Ditta"},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"Content layout aware extraction. Content is extracted and splitted into paragraphs or logical sections of text"},
             {"featureTitle":"Concepts Extraction","featureDesc":"Configurable parameter to control splitting of text. Automated fetching and extraction of content from webpage"}
            ]
            }}

     />} />
     <Route path="/offerings/document-tagging/demo" exact component={DocumentTagging} />


     <Route path="/offerings/active-learning" exact render={ ()  => <ServicesDemo
             details={{"title":"Active Learning","compLink":"/offerings/active-learning/demo","factSheetUrl":"../factsheets/active_learning.pdf","image":"AL","content":"KaaS' Active Learning helps to automate re-training of classifier for unlabelled data. Active learning is a very powerful tool which can be used when there is a shortage of labelled data.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"Convert a single HTML, PDF, or Microsoft Word document into HTML, plain text, or a set of JSON-formatted Answer units that can be used with other Cognitive services."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"This is specifically useful to build Question Answering Systems where it is important to surface smaller, specific, logical sections of a document as an answer and not the complete document."},
             {"featureTitle":"Concepts Extraction","featureDesc":"The service not only finds out what topics are mentioned in a piece of text, but also semantic types and URIs, which would allow you to dive deeper into Linked Data to bring in additional, relevant content. It performs very accurate Word Sense Disambiguation."}
            ]
            }}

     />} />
     <Route path="/offerings/active-learning/demo" exact component={ActiveLearning} />

      <Route path="/offerings/coreference-resolution" exact render={ ()  => <ServicesDemo
             details={{"title":"Coreference Resolution","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=coref","factSheetUrl":"../factsheets/coreference_resolution.pdf","compLink":"/offerings/coreference-resolution/demo","image":"CR","content":"Find expressions that refer to the same entity in a text.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"This service can also used for entity linking and grouping hypernyms / synonyms for multiple entities in clusters within text."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"Coreference resolution in a sense, resolves hyperlinks in natural language removing another dimension of obscurity to the mechanical understanding of language."},
             {"featureTitle":"Concepts Extraction","featureDesc":"It is trained using reinforcement learning based approaches and performs great on the English CoNLL 2012."}
            ]
            }}

     />} />
     <Route path="/offerings/coreference-resolution/demo" exact component={CoreferenceResolution} />

     <Route path="/offerings/triplet-extraction" exact render={ ()  => <ServicesDemo
             details={{"title":"Triplet Extraction","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=tripletExtractor","factSheetUrl":"../factsheets/triplet_extraction.pdf","compLink":"/offerings/relation-extraction/demo","image":"RE","content":"KaaS' Relation Extraction service is a comprehensive variant of subject-verb-predicate (SVP) extraction. Unlike traditional SVP extraction, KaaS' Relation Extraction service extracts SVP for every verb in a sentence.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"Convert a single HTML, PDF, or Microsoft Word document into HTML, plain text, or a set of JSON-formatted Answer units that can be used with other Cognitive services."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"This is specifically useful to build Question Answering Systems where it is important to surface smaller, specific, logical sections of a document as an answer and not the complete document."},
             {"featureTitle":"Concepts Extraction","featureDesc":"The service not only finds out what topics are mentioned in a piece of text, but also semantic types and URIs, which would allow you to dive deeper into Linked Data to bring in additional, relevant content. It performs very accurate Word Sense Disambiguation."}
            ]
            }}

     />} />
     <Route path="/offerings/triplet-extraction/demo" exact component={RelationExtraction} />

    

<Route path="/offerings/semantic-ranking" exact render={ ()  => <ServicesDemo
             details={{"title":"Semantic Ranking","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=semanticReranking","factSheetUrl":"../factsheets/semantic_ranking.pdf","compLink":"/offerings/semantic-ranking/demo","image":"SR","content":"KaaS' Semantic Ranking is a domain-agnostic content recommendation system. KaaS' Semantic Ranking is a powerful, scalable and swift solution to ranking documents or even the paragraphs within, for a given query.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"This service ranks the given text based on Semantic Textual Similarity (STS) by measuring the degree of semantic equivalence."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"Related information can be anywhere in the given text. On this assumption this approach doesn’t penalise similarity score on document length."},
             {"featureTitle":"Concepts Extraction","featureDesc":"This service has used a sliding window protocol with a transformer model to get state of art results."}
            ]
            }}
             
     />} />
     <Route path="/offerings/semantic-ranking/demo" exact component={SemanticRanking} />

     <Route path="/offerings/feedback-service" exact render={ ()  => <ServicesDemo
             details={{"title":"Feedback Service","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=feedback_service","factSheetUrl":"../factsheets/feedback_service.pdf","compLink":"","image":"FB","content":"The idea behind feedback is to take the results that are initially returned from a given query, to gather user feedback, and to use information about whether or not those results are relevant to perform a new query. Explicit user Feedback helps Learn to Rank system (LTR) to re-rank result items for a given query",
             "features":[{"featureTitle":"KaaS Feedback service captures explicit user feedback from the Cognitive Search platform and the Cognitive Chatbot platform."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"Users can provide ratings to each result on a scale of 1 to 5. Rating score is captured by feedback service for each query-result pair."},
             {"featureTitle":"Concepts Extraction","featureDesc":"These scores are stored and submitted to downstream tasks such as LTR service to train a model that can re-rank the results for a similar future query."}
            ]
            }}
             
     />} />
     <Route path="/offerings/feedback-service/demo" exact component={SemanticRanking} />

     <Route path="/offerings/query-suggestion" exact render={ ()  => <ServicesDemo
             details={{"title":"Query Suggestion","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=query_suggestion","factSheetUrl":"../factsheets/query_suggestion.pdf","compLink":"","image":"QS","content":"Query Suggestions is a search experience that displays a list of possible queries that your users can select from as they type.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"Frequently Asked Questions (FAQs) are ingested in the knowledge base in question-answer format."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"As the user is typing the query, whatever query has been written so far is then semantically matched to all the questions in the FAQs."},
             {"featureTitle":"Concepts Extraction","featureDesc":"The questions with the highest semantic overlap with the user's query are suggested in descending order of their relevance."}
            ]
            }}             
     />} />

     <Route path="/offerings/paraphrase-detection" exact render={ ()  => <ServicesDemo
             details={{"title":"Paraphrase Detection","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=paraphrase_detection","factSheetUrl":"../factsheets/paraphrase_detection.pdf","compLink":"","image":"pd","content":"Paraphrase Detection service is used to quantify the similarity of semantics between two documents/questions/sentences.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"Deep Learning based architectures such as Siamese Network and LSTM  for high accuracy."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"Quantified scores helps in sorting and comparing similar candidate sentences."},
             {"featureTitle":"Concepts Extraction","featureDesc":"Blazingly fast execution as compared to traditional methods."}
            ]
            }}             
     />} /> 
    <Route path="/offerings/learn-to-rank" exact render={ ()  => <ServicesDemo
             details={{"title":"Learn To Rank","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=learn_to_rank","factSheetUrl":"../factsheets/learn_to_rank.pdf","compLink":"","image":"ltr","content":"LTR solves the personalised document ranking problem. The aim of LTR is to come up with optimal ordering of documents, in a manner that is personalised to one or a set of users.LTR has the ability to understand individual preferences and over-time modify search results to best suit those preferences.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"LTR Module by KaaS offers the entire pipeline for you to connect to feedback sources, create judgment lists, train your own LTR model, manage created models and rerank your search results using the model you trained."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"Currently compatible with ES and Solr."},
             {"featureTitle":"Concepts Extraction","featureDesc":"We repurposed the Squad 2.0 dataset for the LTR problem and achieved NDCG@5 of 0.9835, compared to 0.7939 achieved by plain ES."}
            ]
            }}             
     />} />

     <Route path="/offerings/video-transcription" exact render={ ()  => <ServicesDemo
             details={{"title":"Video Transcription","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=video_transcription","factSheetUrl":"../factsheets/video_transcription.pdf","compLink":"","image":"vt","content":"Video Transcription service can convert speech to text from recorded video files. It can be used to convert audio and video assets into fully searchable archives for highlight generation, compliance monitoring, content consumption analysis, and monetization etc.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"It is built upon Mozilla’s Deepspeech, an open-source automatic speech recognition (ASR) engine, using a model trained by machine learning techniques based on Baidu's Deep Speech research."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"Comes with the implicit capability to detect audio language, out of the box for better transcription. should be used as for background for speech to text conversion of content in the background."},
             {"featureTitle":"Concepts Extraction","featureDesc":"The Usability and value proposition increases manyfolds when used in combination with the machine translation services transcribing content in multiple languages."}
            ]
            }}             
     />} />

      <Route path="/offerings/sentiment-analysis" exact render={ ()  => <ServicesDemo
             details={{"title":"Sentiment Analysis","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=SentimentClassifier","factSheetUrl":"../factsheets/sentiment_analysis.pdf","compLink":"","image":"sa","content":"Sentiment analysis is the automated process of analyzing text to determine the sentiment expressed (positive, negative or neutral). Some popular sentiment analysis applications include social media monitoring, customer support management, and analyzing customer feedback.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"KaaS' Sentiment Analysis model is based on Transformer Network architecture. More importantly, it is based on AlBert architecture."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"We pick the AlBert xxlarge, fine-tune its language model on user-generated data from platforms where users express themselves like Reddit, Yelp, etc."},
             {"featureTitle":"Concepts Extraction","featureDesc":"We then train the resulting model for Sentiment Analysis which yields a much more robust model."}
            ]
            }}             
     />} />

     <Route path="/offerings/language-translation" exact render={ ()  => <ServicesDemo
             details={{"title":"Language Translation","apiUrl":"https://hackathon.autokaas.com/new_doc?service_name=clia","factSheetUrl":"../factsheets/language_translation.pdf","compLink":"","image":"lt","content":"KaaS Language translation service makes NLP systems compatible with a variety of languages.It converts the inputs and outputs of an NLP system to the language it supports.",
             "features":[{"featureTitle":"Convert to different file types","featureDesc":"Uses two specialised models; first translates to English and the other translates from English. This results in much higher accuracy than otherwise."},
             {"featureTitle":"Build Question Answering Systems","featureDesc":"Uses custom Transformer architecture, which has been pre-trained on 100s GB of text from multiple languages."},
             {"featureTitle":"Concepts Extraction","featureDesc":"Much less social bias compared to usual Word2Vec based translation services."}
            ]
            }}             
     />} />


     

    
    
    
   
   
      </div>
      </Router>}
</div>
    );
  }
}

export default App;
