
import React, { Component } from 'react';
import logo from '../../images/logo.JPG';
import kaasLogo from '../../images/kaas_logo.JPG';
import axios from 'axios';
import '../../App.css';
import HomePage from '../home-page/home-page.js';
import Offerings from '../offerings/offerings.js';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {
  Button,
  CardActions,
  FileUpload,
  LinearProgress,
  Snackbar,
  TextField,
  FontIcon,
  Checkbox,
  SelectField,
  SelectionControlGroup,
  FileInput
} from 'react-md';
import * as apiConstants from '../../service-api-constants.js';
import KaasFactSheet from '../../images/Knowledge as a Service.pdf';
import urlExists from 'url-exists';

class Header extends Component {
    constructor(props) {
    super(props);

    this.state = {
     
      activeUrl:true,
      firstName:'',
      lastName:'',
      feedback:'',
      email:'',
      emailError:false,
       submitted:false,	  
       feedbackResponse:'',
       feedbackStatus:false,
       loaded:true,
    }
  }
  componentDidMount(){
    //this.openWebUrl(apiConstants.CURIOSUMWEBSITEURL);
  }
  openWebUrl = (url) => {    
    urlExists(url,((err, exists)=> {
            if(exists){
                this.setState({activeUrl:true});
                console.log("url exists")
                
            }else{
              this.setState({activeUrl:false});
              console.log("url not exists")      
            }
        }));    
  }

 feedbackChange = (e) => {
    this.setState({feedback:e.currentTarget.value})
  }; 
  
 
  firstNameChange = (e) => {
     this.setState({firstName:e.target.value});
  };

  lastNameChange = (e) => {
    this.setState({lastName:e.target.value});
 };
 
  emailChange = (e) => {
    console.log("this.isEmail(e.target.value)",this.isEmail(e.target.value))
if(e.target.value.length > 0)	   {
    if(this.isEmail(e.target.value)){
     console.log("valid url")
     this.setState({emailError:false,email:e.target.value});
   }else{
     this.setState({emailError:true,email:e.target.value});  
   }
}else{
 this.setState({emailError:false,email:e.target.value});
}
   
  };
  isEmail = (str) => {       
   return str.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
     }
   
    submitFeedback = () => {
    this.setState({submitted:true,feedbackResponse:'',feedbackStatus:false});
    if(this.state.firstName.length>0 && this.state.email.length>0 && this.state.feedback.length>0 && !this.state.emailError){
    this.setState({loaded:false});
    const bodyFormData = new FormData(); 
bodyFormData.set('feedbackType', 'comments');
 bodyFormData.set('feedback', this.state.feedback);
// bodyFormData.set('file', this.state.file !== null && this.state.file !== undefined && this.state.file.name ? this.state.file : 'None');   
 bodyFormData.set('name', `${this.state.firstName + ' ' + this.state.lastName}`); 
 bodyFormData.set('email_id', this.state.email); 
 
   console.log("bodyFormData",bodyFormData)
   axios.post(apiConstants.SUBMIT_FEEDBACK,
     bodyFormData,
    { headers: {'Content-Type': "multipart/form-data" ,
       'x-api-key': this.props.apiKey
       }
       }
     ).then((response) => {
       //handle success
       console.log(response); 
   if(response.data.status){
   this.setState({feedbackResponse:'Feedback submitted Successfully!. We will get back to you soon.',feedbackStatus:response.data.status,submitted:false,loaded:true,firstName:'',
   lastName:'',feedback:'',email:'', emailError:false})
   }else{
     this.setState({feedbackResponse:'Error in submitting the feedback. Please try again',feedbackStatus:response.data.status,submitted:false,loaded:true})
   }
 }).catch((error) => {
   console.log(error);
   this.setState({loaded:true,submitted:false,feedbackStatus:false,feedbackResponse:error.toString()});
 });
    }

 };

    render() {
    
    return (
    <div className="container-fluid">
        <header id="header" className="">
        <div className="row">
		<div className="col-sm-8 w-75 " id="logo">
      <a><img  src={logo} alt="PS"/> {/*<span className="d-inline-flex flex-column pl-4 pr-4 pt-2 pb-2"><span>P</span><span className="text-white">S</span></span>*/}</a>
    </div>
    {/*<div className="col-sm-2 pl-0 pr-0">
    <div class="input-group">
  <input type="text" class="form-control" id="q" autoComplete="off" placeholder="Search for..."/>
  <button role="button" type="submit" id="ps-search" class="ps-search-link" value="Submit"><span class="ps-access">Submit</span></button>
</div>
    </div>
    <div className="col-sm-2 pl-0 pr-0">
    <ul class="ps-masthead-iconsonly">
    <li role="presentation" class="ps-masthead-item-signin">
         <button role="button" aria-haspopup="true" class="ps-profile-link">My kaas</button>
         
    </li>
    <li role="presentation" class="ps-masthead-item-menu">
    <button role="button" aria-haspopup="true" aria-owns="ibm-burger-menu-container" class="ps-menu-link">Site navigation</button>
    
    </li>
    </ul>
    </div>*/}
    </div>
		<hr/>   
	</header>
  <nav className="navbar navbar-expand-sm bg-white navbar-white sticky-top pl-0 pt-0 pb-0 border-bottom">
  {/*<h1 stripes="Striped Text"><span stripes="Striped Text">KaaS</span></h1>*/}
  
  <Link to="/"><img  src={kaasLogo} alt="KaaS"/></Link>
  <ul className="navbar-nav mt-2" style={{"fontSize":"15px","marginLeft":"40px"}}>
     <li className="nav-item ">
    <div class="dropdown">
    <Link className="nav-link pl-0 pr-0"  to="/about-us">About us <i className="fa fa-angle-down"></i></Link>
 
  <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton">
  <a class="dropdown-item p-3 border-bottom" href={KaasFactSheet} target="blank" /*download="Kaas_fact_sheet"*/ >Fact sheet</a>
  <Link to="/meetOurTeam" className="dropdown-item p-3">Meet Our Team</Link>  
  </div>
</div>    
  
    </li>
    <li className="nav-item ml-5">
    <Link to="/offerings" className="nav-link pl-0 pr-0">Services</Link>
    </li>
    <li className="nav-item ml-5">
    <div className="dropdown">
      <a className="nav-link pl-0 pr-0" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Offerings <i className="fa fa-angle-down"></i></a>
      <div className="dropdown-menu p-0" aria-labelledby="dropdownMenuLink">
                   <a className="dropdown-item p-3 border-bottom" href={apiConstants.CURIOSUMWEBSITEURL} target="blank"  >KaaS Cognitive Search</a>
                    <a className="dropdown-item p-3 border-bottom" href={apiConstants.CHATBOTURL} target="blank" >KaaS Cognitive Chatbot</a>  
<a className="dropdown-item border-bottom  p-3" href="#" data-toggle="modal" data-target='#myModalSoon'>KaaS Studio</a>
<a className="dropdown-item border-bottom  p-3" href="#" data-toggle="modal" data-target='#myModalSoon'>Pinaka</a>
                    <a className="dropdown-item  p-3" href="#" data-toggle="modal" data-target='#myModalSoon'>Document Insights</a>                  
                  </div>
    </div>
    </li>
   {/* <li className="nav-item ml-5">
    <div className="dropdown">
      <a className="nav-link pl-0 pr-0"  role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Use Cases <i className="fa fa-angle-down"></i></a>
      <div className="dropdown-menu p-0" aria-labelledby="dropdownMenuLink">
                    <a className="dropdown-item p-3 border-bottom" href="#">Action</a>
                    <a className="dropdown-item p-3 border-bottom" href="#">Another action</a>
                    <a className="dropdown-item p-3" href="#">Something else here</a>
                  </div>
    </div>
  </li>*/}
<li className="nav-item ml-5">
    <div className="dropdown ">
      <span className="nav-link pl-0 pr-0"  role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Learn <i className="fa fa-angle-down"></i></span>
      <div className="dropdown-menu p-0" aria-labelledby="dropdownMenuLink">
      <Link to="/documents" className="dropdown-item p-3 border-bottom">Docs<br/><small>Product guides</small></Link>
      <Link to="/blogs" className="dropdown-item p-3 border-bottom">Blogs<br/><small>Tutorial, updates, people</small></Link>
                    <a className="dropdown-item p-3" href="#">Videos</a>
                  </div>
    </div>
    </li>
  </ul>
  
  <Button className="rounded-pill ml-auto" href="#" data-toggle="modal" data-target="#myModal" raised primary>Contact us</Button>     
 
  
</nav>
<div className="modal" id="myModalSoon">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content p-4">
      <p> Coming soon. Please contact <a href="mailto:sudharsan.rangarajan@publicissapient.com" target="_top">sudharsan.rangarajan@publicissapient.com</a> for further queries.</p>
    </div>
  </div>
</div>
<div className="modal" id="myModalInfo">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content p-4">
      <p> Currently KaaS Cognitive Search environment is unavailable. Please contact <a href="mailto:kaas_core_team@publicissapient.com" target="_top">kaas_core_team@publicissapient.com</a> for further queries.</p>
    </div>
  </div>
</div>

<div className="modal" id="myModal">
  <div className="modal-dialog">
    <div className="modal-content">

     
      <div className="modal-header">
        <h3 className="modal-title mx-auto">CONTACT US</h3>
        
      </div>

     
      <div className="modal-body mx-auto">
        <form className={`${this.state.submitted ? 'was-validated' : 'needs-validation'}`}  novalidate>
          <table className="form-table ">
          <tbody>
             <tr>
               <td><span className="text-danger">*</span></td>
               <td><input type="text" className="form-control" placeholder="firstname" id="firstname" value={this.state.firstName} onChange={this.firstNameChange} required/></td>
               <td><input type="text" className="form-control" placeholder="lastname" id="lastname" value={this.state.lastName} onChange={this.lastNameChange}/></td>
             </tr>
             <tr>
             <td><span className="text-danger">*</span></td>
               <td colSpan="2" >
               <input type="email" className="w-100 form-control" placeholder="Email id" id="email" value={this.state.email} onChange={this.emailChange} required/>
               </td>
               
               </tr>
               {/*<tr>
               <td colSpan="2">
               
               <input className="w-100 form-control" type="text" placeholder="company Name"/>
               </td>
               
               </tr>
               <tr>
               <td colSpan="2">
                
  <select className="form-control" id="sel1" placeholder="Country">
    <option>Country</option>
    <option>United States</option>
    <option>India</option>
    <option>London</option>
  </select>

               </td>
               
               </tr>*/}
               <tr>
               <td><span className="text-danger">*</span></td>
               <td colSpan="2">
               <input type="text" className="w-100 form-control" placeholder="Comments" id="feedback" value={this.state.feedback} onChange={this.feedbackChange} required/>  
               </td>
               
               </tr>
               <tr>
                 <td colSpan="3" className="text-center"><button type="button" className="btn btn-secondary rounded border"  disabled={!this.state.loaded} onClick={this.submitFeedback} >Submit</button> 
                 <button type="button" data-dismiss="modal" className="btn btn-secondary rounded border ml-2">close</button></td>
                 </tr>
                 </tbody>
            </table>
            {this.state.feedbackResponse.length>0 ? this.state.feedbackStatus ? <div className="p-1 text-success">{this.state.feedbackResponse}</div> : <div className="p-1 text-danger">{this.state.feedbackResponse}</div> : ""}	
          </form>
      </div>
      

    </div>
  </div>
</div>
</div>
);
}
}
export default Header;