

import React, { Component } from 'react';
import '../../App.css';
import Footer from '../footer/footer.js';
import MC from '../../images/services/mc.jpg';
import { TabsContainer, Tabs, Tab } from 'react-md';
import axios from 'axios';
import MDSpinner from "react-md-spinner";
import {
  Button,
  CardActions,
  FileUpload,
  LinearProgress,
  Snackbar,
  TextField,
  FontIcon,
  Checkbox,
  SelectField,
  SelectionControlGroup,
  FileInput
} from 'react-md';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as apiConstants from '../../service-api-constants.js';


class WordEmbedding extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            question: '',
            result: null,
            error:'',
            loaded:true,
            menuItems: ["What do robots that resemble humans attempt to do?",
            "How many passing touchdowns were there in the game?",
            "Who threw the longest touchdown pass of the game?"
          ]
         
        }
    }

    componentDidMount(){     
     
    }

   

    questionChange = (value,e) => {
     
      this.setState({question:  value});
    };
    
    kbSelect = (value) => {
      this.setState({selectedKb: value,error:''});
    };     
    
    analyze = () => {
       // this.setState({question: e.target.value});
      if(this.state.question.length > 0){
        this.setState({loaded:false,result:null});
        axios.post(apiConstants.WORDEMBEDDING, {"query": this.state.question},
          {headers: {'Content-Type': 'application/json' }}
            ).then((response) => {
                console.log("response", response);                
                if(response.data.errno){
                    this.setState({loaded:true,error:response.data.errno});
                }else{
                    this.setState({loaded:true,result:response.data});
                }                
                
            })
            .catch((error) => {             
                console.log("error", error);
                this.setState({loaded:true,error:error });

            });
          }else{
            this.setState({loaded:true,result:''});
          }
    }

    answerChange = (value,event) => {
      this.setState({[event.target.name]: value});
    };

        
    render() {
    const {result,question} = this.state;
    return (

        <div className="container-fluid pl-0 pr-0 ">
        <div className="d-flex flex-row w-100">
        <div className="w-10 p-5">
        <Link to="/offerings/phrase-completion"  className="fa fa-arrow-left float-left pl-2 mx-auto"> Back</Link>
          </div>
          <div className="pl-3 pt-4 pb-5 pr-3  w-75">  
            <h2 className="pt-3 pl-3">Word Embedding</h2>
            <div class="card w-100 border-0">
       <div class="card-body pt-2">
       <div className="d-flex flex-row"><TextField
      id="floating-center-title"
     label="Question"
      lineDirection="center"
      placeholder="Type a question"
      className="text-field mt-3"
      value={this.state.question}
      rows={1}
      onChange={this.questionChange}
    />
    <Button className="mt-4 ml-3 align-self-end mb-1 rounded-pill" disabled={!this.state.question.length>0} onClick={this.analyze} raised primary>Analyze</Button>
     </div>
       </div>
       </div>   
           {!this.state.loaded ? <MDSpinner singleColor="#03a9f4" className="m-5" size={70} borderSize={5}/> : ""}
           {result && result.embedding_result && result.embedding_result.length>0 && <div className="d-flex flex-column w-100 mt-2 pl-4">
           <h3>Results</h3>
           <div className="card-deck">
           {result.embedding_result.map((res,index)=>(
              <div className="card border-0 ">
              <div class="card-body p-1">
              <h6 className="card-title font-weight-bold">{res.title} : <br/>{res.description}</h6>
              <ul className="pl-1 embedding-result">
             {res.word_list.map((res,index)=>(

            <li key={index}>{res}</li>
     

             ))}
             
             </ul>
             </div>
             </div> 
           ))}            
            
           </div>
       </div> } 
    
           {
             this.state.error.length > 0 ? <span className="text-danger pl-4">{this.state.error}</span> : ""
           }
    
           </div>  
           </div>
           <Footer/>
     
        </div>
);
}
}

export default WordEmbedding;