
import React, { Component } from 'react';

class Footer extends Component {
    
    render() {
    
    return (
        <footer className="w-100 mt-3 border-top  pl-0 pr-0">
        <div className="bg-light pl-3 pt-4 pb-4">
     
        <ul>
        <li><a href="#" data-toggle="modal" data-target="#myModal">Contact KaaS</a></li>
        <li><a href="#">Privacy</a></li>
        <li><a href="#">Terms of use</a></li>
        <li><a href="#">Accessibility</a></li>
        <li><a href="#">Feedback</a></li>
        
        </ul>
          </div>
         </footer>
);
}
}
export default Footer;



