
import React, { Component } from 'react';
import '../../App.css';
import Footer from '../footer/footer.js';

import AI from '../../images/AI.gif';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


class Aboutus extends Component {
    
    render() {
    
    return (
    <div>
        <div className="container-fluid pl-0 pr-0">
           <div className="bg-black text-white pt-0 pl-4 pb-0 d-flex flex-row">
           <div className="w-50 h-100 d-flex flex-column">
           <h1 className="d-inline-block pl-3 text-white offerings-header align-top pt-3">Enterprise-ready <br/>AI</h1>
           <div className="d-flex" style={{"height":"300px"}}>
           <h3 className="d-inline-block pl-3 text-white align-self-end">KaaS is a suite of enterprise-ready <br/>AI  services, applications, and <br/>tooling.</h3>
           </div>
           </div>
           <div className="w-50">
           <img class="card-img-top border-bottom" src={AI} alt="Card image"/>
           </div>
           
           </div>
        </div>
    <div className="container-fluid pb-3 bg-dark">
       <div className="d-flex flex-row p-4">
  <div class="bg-dark w-25">  
 
    <h4 class="pb-3 text-white font-weight-bold align-top">Why KaaS?</h4>
    
   
  
  </div>
  <div class="bg-dark w-50">
  <h1 className="text-white w-100">Build your competitive advantage with AI</h1>
  <h3 className="text-white w-75" style={{"overflowWrap":"break-word","whiteSpace":"pre-wrap"}}>
  KaaS helps you unlock the value of your data in entirely new, profound ways. By freeing your employees from repetitive tasks, you can empower your teams to focus on more creative, 
  higher-value work. With insights from KaaS, you can predict and shape future business outcomes, while rethinking your practices and workflows.</h3>
  
  
  </div>
  

    </div>
  
    </div>
    <div className="container-fluid bg-light p-5">
    <h1>AI for your industry</h1>
<h4 className="w-50">With KaaS, you can put AI to work in any industry. Augment your teams' productivity by freeing your employees from repetitive tasks and empowering them to focus on the high-value work critical to your enterprise.</h4>
    </div>
    <div className="container-fluid pl-0 pr-0">
    <Footer/>
    </div>
 </div>   
);
}
}
export default Aboutus;