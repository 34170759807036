

import React, { Component } from 'react';
import '../../App.css';
import Footer from '../footer/footer.js';
import MC from '../../images/services/mc.jpg';
import { TabsContainer, Tabs, Tab } from 'react-md';
import axios from 'axios';
import MDSpinner from "react-md-spinner";
import {
  Button,
  CardActions,
  FileUpload,
  LinearProgress,
  Snackbar,
  TextField,
  FontIcon,
  Checkbox,
  SelectField,
  SelectionControlGroup,
  FileInput
} from 'react-md';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as apiConstants from '../../service-api-constants.js';


class PhraseCompletion extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            question: '',
            result: null,
            error:'',
            loaded:true,
            menuItems: ["What do robots that resemble humans attempt to do?",
            "How many passing touchdowns were there in the game?",
            "Who threw the longest touchdown pass of the game?"
          ]
         
        }
    }

    componentDidMount(){     
     this.getIndex();
     
    }

    getIndex = (update=false) => {
      this.setState({loaded:false});
      axios({
        method: 'get',
        url: apiConstants.GETKNOWLEDGEBASES,
        config: { headers: {'Content-Type': 'application/json' },
    }
        })
        .then((response) => {
            //handle success
            console.log(response);
            const data = response.data;
            if(data.errno){
              this.setState({error:data.errno.toString(),loaded:true});
            } else{
              if(Array.isArray(data) && data.length>0){
              //data.map((item,index)=>{           
              //  item.checked=false;                
              // });
              console.log("data",data);
              this.setState({knowledgeBaseNames:data,loaded:true});  
              
            }
            } 
                   
        })
        .catch((error) => {
            console.log("error",error);
            this.setState({error:error.toString(),loaded:true});
        });
    };

    questionChange = (e) => {
        console.log("event",e);
      this.setState({question:  e.target.value});
    };
    
    kbSelect = (value) => {
      this.setState({result: null,selectedKb: value,error:''});
    };     
    
    analyze = (e) => {
        this.setState({question: e.target.value});
      if(e.target.value.length >= 2 || e.keyCode == 13 || e.keyCode == 32){
        this.setState({loaded:false,result:null});
        axios.post(apiConstants.PHRASECOMPLETION, {"text": e.target.value},
          {headers: {'Content-Type': 'application/json' }}
            ).then((response) => {
                console.log("response", response);
                if(response.data.errno){
                    this.setState({loaded:true,result:{

                        "original_phrase": "nvidia",
                      
                        "suggested_phrases": [
                      
                          "nvidia titan gtx",
                      
                          "nvidia smi nvlink",
                      
                          "nvidia tesla p100",
                      
                          "nvidia icd",
                      
                          "nvidia gpu"
                      
                        ]
                      
                      }});
                }else{
                    this.setState({loaded:true,result:response.data});
                }
                
            })
            .catch((error) => {             
                console.log("error", error);
                this.setState({loaded:true,result: {

                    "original_phrase": "nvidia",
                  
                    "suggested_phrases": [
                  
                      "nvidia titan gtx",
                  
                      "nvidia smi nvlink",
                  
                      "nvidia tesla p100",
                  
                      "nvidia icd",
                  
                      "nvidia gpu"
                  
                    ]
                  
                  }});

            });
          }else{
            this.setState({loaded:true,result:''});
          }
    }

    answerChange = (value,event) => {
      this.setState({[event.target.name]: value});
    };

    addAnswer = () => {
      const answer = this.state.answer;
      let len = answer.length;
      answer.push(`answer${len-1}`);
      this.setState({answer:answer});
    };
    
    render() {
    const {result,question} = this.state;
    return (

        <div className="container-fluid pl-0 pr-0 ">
        <div className="d-flex flex-row w-100">
        <div className="w-10 p-5">
        <Link to="/offerings/phrase-completion"  className="fa fa-arrow-left float-left pl-2 mx-auto"> Back</Link>
          </div>
          <div className="pl-3 pt-4 pb-5 pr-3  w-90">  
            <h2 className="pt-3 pl-3">Phrase Completion</h2>
            <div class="card w-100 border-0">
       <div class="card-body pt-2">
               <div className="d-flex flex-row">
                  <div className="w-65">
                  <input  onKeyUp={this.analyze} placeholder="Search query" className="text-field mt-3 w-100 border border-top-0 border-left-0 border-right-0 p-2" fullWidth={true} />
                   
                  </div>
    
     </div>
       

       </div>
       </div>   
           {!this.state.loaded ? <MDSpinner singleColor="#03a9f4" className="m-5" size={70} borderSize={5}/> : ""}
           {result && result.suggested_phrases && this.state.question.length > 0 && <div className="d-flex flex-column w-100 mt-2 pl-4">
           <h3>Results</h3>
           <ul className="phrase-result">
             {result.suggested_phrases.map((res,index)=>(

            <li key={index}>{res}</li>
     

             ))}
            </ul>
       </div> } 
    
           {
             this.state.error.length > 0 ? <span className="text-danger pl-4">{this.state.error}</span> : ""
           }
    
           </div>  
           </div>
           <Footer/>
     
        </div>
);
}
}

export default PhraseCompletion;